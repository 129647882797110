import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import editReducer from './edit';
import loadingReducer from './loading';
import fullImgReducer from './fullImg';
import errorReducer from './error';
import contentReducer from './content';
import additionalDescContentReducer from './additionalDescContent';

const store = configureStore({
  reducer: {
    auth: authReducer,
    edit: editReducer,
    loading: loadingReducer,
    fullImg: fullImgReducer,
    error: errorReducer,
    content: contentReducer,
    additionalDescContent: additionalDescContentReducer,
  },
});

export default store;
