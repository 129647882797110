export const formatTabName = (name, forQuery = false) => {
  if (name.length === 0) {
    return name;
  }

  if (!forQuery) {
    const formattedName = name.slice(1).split('-').join(' ');

    return `${`${formattedName.charAt(0)}`.toUpperCase()}${formattedName.slice(
      1
    )}`;
  }

  return name.slice(1);
};

export const isAuthMode = (location) => {
  // Get the list of path names
  const names = location.pathname.split('/');

  // If the path starts with /auth
  return names.length > 1 && names[1] === 'auth';
};

export function generateRandomId(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
}

export function getRandomInteger(a, b) {
  // Ensure that a and b are integers and b is greater than a
  if (!Number.isInteger(a) || !Number.isInteger(b) || b < a) {
    throw new Error(
      'Invalid input. Both a and b must be integers, and b must be greater than a.'
    );
  }

  // Calculate a random integer between a and b (inclusive)
  return Math.floor(Math.random() * (b - a + 1)) + a;
}

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export function shuffle(arr) {
  var j, x, index;
  for (index = arr.length - 1; index > 0; index--) {
    j = Math.floor(Math.random() * (index + 1));
    x = arr[index];
    arr[index] = arr[j];
    arr[j] = x;
  }
  return arr;
}

export const getLastParams = (searchStr, delimeter) => {
  const arr = searchStr.split(delimeter);
  return arr[arr.length - 1];
};
