import { createSlice } from '@reduxjs/toolkit';

/* 
  SPINNER STATES:
    LOADING - Initial state -> spinning
    FINISH - Start to play finish animation
    COMPLETE - Completed all animations
*/

const initialLoadingState = {
  state: 'loading',
  show: false,
  title: 'Loading',
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialLoadingState,
  reducers: {
    setTitle(state, action) {
      state.title = action.payload || 'Loading';
    },
    finish(state) {
      state.state = 'finish';
    },
    complete(state) {
      state.state = 'complete';
    },
    show(state) {
      state.show = true;
    },
    hide(state) {
      state.show = false;
    },
    reset(state) {
      state.state = 'loading';
      state.show = false;
    },
  },
});

export const loadingActions = loadingSlice.actions;

export default loadingSlice.reducer;
