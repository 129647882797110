import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UilEstate,
  UilImageEdit,
  UilEditAlt,
  UilFileEditAlt,
  UilCommentEdit,
  UilSignout,
} from '@iconscout/react-unicons';

import MainContent from '../../content/MainContent';
import About from '../../about/About';

import EditImageModal from '../editImageModal/EditImageModal';
import EditDescModal from '../editDescModal/EditDescModal';
import EditNameModal from '../editAbout/editNameModal/EditNameModal';
import EditSocialMediaModal from '../editAbout/editSocialMediaModal/EditSocialMediaModal';

import ErrorModal from '../../ui/errorModal/ErrorModal';
import LoadingModal from '../../ui/loadingModal/LoadingModal';

import useAxios from '../../../hooks/useAxios';
import axiosInstance from '../../../util/apis';

import { editActions } from '../../../store/edit';
import { authActions } from '../../../store/auth';
import { additionalDescContentActions } from '../../../store/additionalDescContent';
import { routes } from '../../../util/config';

import commonClasses from '../../../util/common.module.css';
import classes from './Toolbar.module.css';

const ImageToolbar = ({ currentTab, openEditImgModal, openEditDescModal }) => {
  if (currentTab === routes.ABOUT) return null;

  return (
    <Fragment>
      {/* Edit Image Icon */}
      <div className={classes['icon__wrapper']} onClick={openEditImgModal}>
        <UilImageEdit className={classes['icon']} />
        <span className={`${classes['tooltip']} ${commonClasses['tooltip']}`}>
          Edit images
        </span>
      </div>

      {/* Edit Description Icon */}
      <div className={classes['icon__wrapper']} onClick={openEditDescModal}>
        <UilEditAlt className={classes['icon']} />
        <span className={`${classes['tooltip']} ${commonClasses['tooltip']}`}>
          Edit description
        </span>
      </div>
    </Fragment>
  );
};

const AboutToolbar = ({
  currentTab,
  openEditNameModal,
  openTabsModal,
  openEditSocialMediaModal,
}) => {
  if (currentTab !== routes.ABOUT) return null;

  return (
    <Fragment>
      {/* Edit Name Icon */}
      <div className={classes['icon__wrapper']} onClick={openEditNameModal}>
        <UilFileEditAlt className={classes['icon']} />
        <span className={`${classes['tooltip']} ${commonClasses['tooltip']}`}>
          Edit name
        </span>
      </div>

      {/* Edit Tabs Icon */}
      <div className={classes['icon__wrapper']} onClick={openTabsModal}>
        <UilEditAlt className={classes['icon']} />
        <span className={`${classes['tooltip']} ${commonClasses['tooltip']}`}>
          Edit tabs
        </span>
      </div>

      {/* Edit Social Media Icon */}
      <div
        className={classes['icon__wrapper']}
        onClick={openEditSocialMediaModal}
      >
        <UilCommentEdit className={classes['icon']} />
        <span className={`${classes['tooltip']} ${commonClasses['tooltip']}`}>
          Edit social media
        </span>
      </div>
    </Fragment>
  );
};

const Toolbar = () => {
  // Use param
  const params = useParams();

  // Use axios hook
  const { error, completed, sendRequest, reset: resetAxios } = useAxios();

  // Get current tab
  const currentTab = params.tab ? `/${params.tab}` : null;

  // State of current auth
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const triedToAuthenticate = useSelector(
    (state) => state.auth.triedToAuthenticate
  );

  // State pf current tab description
  const tabDesc = useSelector((state) => state.content.tabDescription);

  // Use dispatch
  const dispatch = useDispatch();

  // Use navigate
  const navigate = useNavigate();

  // Create state for showing edit image modal
  const [showEditImgModal, setShowEditImgModal] = useState(false);

  // Create state for showing edit image modal
  const [showEditDescModal, setShowEditDescModal] = useState(false);

  // Create state for showing edit name modal
  const [showEditNameModal, setShowEditNameModal] = useState(false);

  // Create state for showing edit name modal
  const [showEditSocialMediaModal, setShowEditSocialMediaModal] =
    useState(false);

  // Handle signin GET complete
  useEffect(() => {
    if (!completed) return;

    if (!error) {
      dispatch(authActions.signin());
    }

    dispatch(authActions.tryToAuthenticate());

    resetAxios();
  }, [completed, resetAxios, error, dispatch]);

  // On component load, try to signin using cookie
  useEffect(() => {
    sendRequest({
      axiosInstance,
      url: '/auth',
      method: 'GET',
    });
  }, [sendRequest]);

  // If not authenticated, redirect to login page
  useEffect(() => {
    if (!currentTab || !triedToAuthenticate) return;

    if (!isAuthenticated) {
      navigate('/auth/signin', { replace: true });
    }
  }, [navigate, currentTab, isAuthenticated, triedToAuthenticate]);

  const openEditImgModal = () => {
    setShowEditImgModal(true);
  };

  const closeEditImgModal = useCallback(() => {
    // Close the modal
    setShowEditImgModal(false);

    if (currentTab !== routes.ABOUT) {
      // Reload main content
      dispatch(editActions.reloadMainContent());
      // Reset saved additional description contents so component will fetch for updated content
      dispatch(additionalDescContentActions.reset());
    }

    if (currentTab === routes.ABOUT) {
      // Reload about component
      dispatch(editActions.reloadAbout());
    }
  }, [dispatch, currentTab]);

  const openEditDescModal = () => {
    setShowEditDescModal(true);
  };

  const closeEditDescModal = useCallback(() => {
    setShowEditDescModal(false);
  }, []);

  const openEditNameModal = () => {
    setShowEditNameModal(true);
  };

  const closeEditNameModal = useCallback(() => {
    setShowEditNameModal(false);
  }, []);

  const openEditSocialMediaModal = () => {
    setShowEditSocialMediaModal(true);
  };

  const closeEditSocialMediaModal = useCallback(() => {
    setShowEditSocialMediaModal(false);
  }, []);

  const homeClickHandler = () => {
    dispatch(authActions.signout());
    navigate('/', { replace: true });
  };

  const signoutClickHandler = () => {
    sendRequest({
      axiosInstance,
      url: '/auth',
      method: 'DELETE',
    });

    dispatch(authActions.signout());
    navigate('/', { replace: true });
  };

  if (!currentTab || !triedToAuthenticate) return null;

  return (
    <Fragment>
      {/* Loading Modal */}
      <LoadingModal />

      {/* Error Modal */}
      <ErrorModal />

      {/* Edit Image Modal */}
      <EditImageModal
        show={showEditImgModal}
        closeModal={closeEditImgModal}
        currentTab={currentTab}
      />

      {/* Edit Description Modal */}
      <EditDescModal
        show={showEditDescModal}
        currentTab={currentTab}
        closeModal={closeEditDescModal}
        desc={tabDesc}
      />

      {/* Edit Name Modal */}
      <EditNameModal show={showEditNameModal} closeModal={closeEditNameModal} />

      {/* Edit Social Media Modal */}
      <EditSocialMediaModal
        show={showEditSocialMediaModal}
        closeModal={closeEditSocialMediaModal}
      />

      {/* Toolbar */}
      <div className={classes['toolbar__container']}>
        <div className={classes['icons__container']}>
          {/* Editing Image Toolbars */}
          <ImageToolbar
            currentTab={currentTab}
            openEditImgModal={openEditImgModal}
            openEditDescModal={openEditDescModal}
          />

          {/* Edit About Toolbars */}
          <AboutToolbar
            currentTab={currentTab}
            openEditNameModal={openEditNameModal}
            openTabsModal={openEditImgModal}
            openEditSocialMediaModal={openEditSocialMediaModal}
          />

          {/* Home Icon */}
          <div className={classes['icon__wrapper']} onClick={homeClickHandler}>
            <UilEstate className={classes['icon']} />
            <span
              className={`${classes['tooltip']} ${commonClasses['tooltip']}`}
            >
              Exit edit mode
            </span>
          </div>

          {/* Signout Icon */}
          <div
            className={classes['icon__wrapper']}
            onClick={signoutClickHandler}
          >
            <UilSignout className={classes['icon']} />
            <span
              className={`${classes['tooltip']} ${commonClasses['tooltip']}`}
            >
              Signout
            </span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      {currentTab === routes.ABOUT ? (
        <About tabName={currentTab} />
      ) : (
        <MainContent tabName={currentTab} />
      )}
    </Fragment>
  );
};

export default Toolbar;
