import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useAxios from '../../hooks/useAxios';
import axiosInstance from '../../util/apis';

import { contentActions } from '../../store/content';

import classes from './Header.module.css';

const Header = () => {
  // Use Axios
  const {
    error,
    completed,
    response,
    sendRequest,
    reset: resetAxios,
  } = useAxios();

  // Get user info state
  const info = useSelector((state) => state.content.info);

  // Use dispatch
  const dispatch = useDispatch();

  // On component load, fetch user info
  useEffect(() => {
    sendRequest({
      axiosInstance,
      url: '/users/me',
      method: 'GET',
    });
  }, [sendRequest, resetAxios]);

  // Handle GET request complete
  useEffect(() => {
    if (!completed) return;

    // If no error, set global state
    if (!error) {
      dispatch(
        contentActions.setInfo({
          name: response.name,
          title: response.title,
          role: response.role,
          photo: response.url,
        })
      );
    }
  }, [error, completed, response, resetAxios, dispatch]);

  return (
    <header className={classes['container']}>
      {info.name && (
        <a href='/' className={classes['name']}>
          {info.name}
        </a>
      )}
      {info.title && <p className={classes['title']}>{info.title}</p>}
    </header>
  );
};

export default Header;
