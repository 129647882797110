import { createSlice } from '@reduxjs/toolkit';

const initialContentState = {
  imgData: [],
  tabDescription: '',
  info: { name: '', title: '', photo: '', role: '' },
  tabs: [],
  socialMedia: [],
  layoutOptions: { cols: 0, gap: 16, width: 0 },
};

const contentSlice = createSlice({
  name: 'content',
  initialState: initialContentState,
  reducers: {
    setImgData(state, action) {
      state.imgData = [...action.payload];
    },
    setTabDescription(state, action) {
      state.tabDescription = action.payload;
    },
    setInfo(state, action) {
      state.info = { ...action.payload };
    },
    setSocialMedia(state, action) {
      state.socialMedia = [...action.payload];
    },
    setTabs(state, action) {
      state.tabs = [...action.payload];
    },
    setLayoutOptions(state, action) {
      state.layoutOptions = { ...action.payload };
    },
    reset(state) {
      state.imgData = [];
      state.tabDescription = initialContentState.tabDescription;
    },
  },
});

export const contentActions = contentSlice.actions;

export default contentSlice.reducer;
