import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { errorActions } from '../store/error';

const useErrorModal = () => {
  // Create state for modal close handler
  const [modalCloseHandler, setModalCloseHandler] = useState({});

  // Create state for delay opening
  const [openTimeout, setOpenTimeout] = useState(null);

  // State of the error modal
  const errorModalState = useSelector((state) => state.error.state);

  // Use dispatch
  const dispatch = useDispatch();

  // Handle modal closed complete
  useEffect(() => {
    // If error modal state is not closed, do nothing
    if (errorModalState !== 'closed') return;

    // If has a modal close handler, call it
    modalCloseHandler.onClose && modalCloseHandler.onClose();

    // If has delay open
    openTimeout && clearTimeout(openTimeout);
    setOpenTimeout(null);

    // Reset the error modal
    dispatch(errorActions.clear());

    return () => {
      dispatch(errorActions.clear());
      openTimeout && clearTimeout(openTimeout);
      setOpenTimeout(null);
    };
  }, [dispatch, errorModalState, modalCloseHandler, openTimeout]);

  // 1. Save the modal close handler
  // 2. Set the error message
  // 3. Open the modal
  const openErrorModal = useCallback(
    (message, onClose, delay = 0) => {
      // If modal is opened, do nothing
      // if (errorModalState !== 'pending') return;

      const open = setTimeout(() => {
        setModalCloseHandler({ onClose });
        dispatch(errorActions.open(message));
      }, delay);

      setOpenTimeout(open);
    },
    [dispatch]
  );

  return openErrorModal;
};

export default useErrorModal;
