import React, { Fragment, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useWindowSize, useDebounce } from 'usehooks-ts';
import { useDispatch } from 'react-redux';

import Header from './components/header/Header';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import MainContent from './components/content/MainContent';
import About from './components/about/About';
import NotFound from './components/not_found/NotFound';

import Signin from './components/auth/authenticate/Signin';
import Toolbar from './components/auth/toolbar/Toolbar';

import { routes, getLayoutOptions, deounceDelay } from './util/config';
import { contentActions } from './store/content';

import './App.css';
import commonClasses from './util/common.module.css';
import './components/about/html.css';

const App = () => {
  // Use dispatch
  const dispatch = useDispatch();

  // Use window size hook to get window width
  const { width } = useWindowSize();

  // Use debounc hook to update window width after delay
  const debouncedWidth = useDebounce(width, deounceDelay);

  // After debounce, update the global layout option state
  useEffect(() => {
    dispatch(
      contentActions.setLayoutOptions({
        ...getLayoutOptions(debouncedWidth),
        width: debouncedWidth,
      })
    );
  }, [debouncedWidth, dispatch]);

  return (
    <Fragment>
      {/* Background */}
      <div className='bg'></div>

      {/* App */}
      <div
        className={`app ${commonClasses['custom_scrollbar']} ${commonClasses['custom_scrollbar__dark']}`}
      >
        <Header />
        <Navbar />
        <Routes>
          <Route
            path={routes.ENVIRONMENT}
            element={<MainContent tabName={routes.ENVIRONMENT} />}
          />

          <Route
            path={routes.CHARACTER_AND_COSTUME}
            element={<MainContent tabName={routes.CHARACTER_AND_COSTUME} />}
          />

          <Route
            path={routes.PROP_DESIGN}
            element={<MainContent tabName={routes.PROP_DESIGN} />}
          />

          <Route
            path={routes.THREE_D}
            element={<MainContent tabName={routes.THREE_D} />}
          />

          <Route
            path={routes.PLEIN_AIR_AND_OTHERS}
            element={<MainContent tabName={routes.PLEIN_AIR_AND_OTHERS} />}
          />

          <Route
            path={routes.ABOUT}
            element={<About tabName={routes.ABOUT} />}
          />

          <Route path='/auth/signin' element={<Signin />} />

          <Route path='/auth/:tab' element={<Toolbar />} />

          <Route path='/' element={<Navigate to={routes.ENVIRONMENT} />} />

          <Route
            path='/auth'
            element={<Navigate to={`/auth${routes.ENVIRONMENT}`} />}
          />

          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Fragment>
  );
};

export default App;
