import Modal from '../modal/Modal';
import ModalHeader from '../modalHeader/ModalHeader';

import modalClasses from '../../ui/modal/Modal.module.css';
import classes from './FormModal.module.css';

const ModalOverlay = ({ closeModal, options, children }) => {
  // Default options
  const _options = {
    disableButtons: false,
    disableBackdropClose:
      options.disableBackdropClose === undefined
        ? false
        : options.disableBackdropClose,
    buttons: {
      confirm: {
        name: 'Confirm',
        className: '',
        onClick: null,
        isDisabled: false,
      },
      cancel: {
        name: 'Cancel',
        className: '',
        onClick: null,
        isDisabled: false,
      },
    },
    zIndex: options.zIndex?.toString() || '6',
    containerStyle: options.containerStyle || {},
    title: options.title || 'Tab',
    style: { ...options.style },
    headerColor: options.headerColor,
  };

  // Override default options
  if (options) {
    if (options.disableButtons !== undefined) {
      _options.disableButtons = options.disableButtons;
    }

    if (!_options.disableButtons && options.buttons) {
      if (options.buttons.confirm) {
        _options.buttons.confirm = {
          ..._options.buttons.confirm,
          ...options.buttons.confirm,
        };
      }

      if (options.buttons.cancel) {
        _options.buttons.cancel = {
          ..._options.buttons.cancel,
          ...options.buttons.cancel,
        };
      }
    }
  }

  // Destructure options
  const { confirm: confirmBtn, cancel: cancelBtn } = _options.buttons;

  // If overlay container is clicked, close the modal
  const overlayContainerClickHandler = (e) => {
    if (_options.disableBackdropClose) return;

    if (!e.target.className.includes) return;

    // Check if the target IS the overlay container and not its children
    e.target.className.includes(classes['overlay__container']) && closeModal();
  };

  return (
    <div
      className={`${classes['overlay__container']} ${modalClasses['modal-overlay']}`}
      onClick={overlayContainerClickHandler}
      style={{ zIndex: _options.zIndex.toString() }}
    >
      <div className={`${classes['edit__container']}`} style={_options.style}>
        {/* Header */}
        <ModalHeader
          title={_options.title}
          closeModal={closeModal}
          color={_options.headerColor}
        />

        {/* Content */}
        {children}

        {/* Buttons */}
        {!_options.disableButtons && (
          <div className={classes['btns__container']}>
            <button
              className={`${classes['btn']} ${classes['btn-confirm']} ${
                confirmBtn.isDisabled ? classes['btn__disabled'] : ''
              } ${confirmBtn.className}`}
              onClick={confirmBtn.onClick}
            >
              {confirmBtn.name}
            </button>
            <button
              className={`${classes['btn']} ${classes['btn-cancel']} ${cancelBtn.className}`}
              onClick={() => {
                if (cancelBtn.onClick) {
                  cancelBtn.onClick();
                } else {
                  closeModal();
                }
              }}
            >
              {cancelBtn.name}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const FormModal = ({ show, closeModal, options, children }) => {
  options.zIndex = options.zIndex || 6;

  return (
    <Modal
      show={show}
      closeModal={closeModal}
      showCloseIcon={false}
      zIndex={options.zIndex || 6}
      onExited={options.onExited}
      onEntered={options.onEntered}
    >
      {show && (
        <ModalOverlay
          closeModal={closeModal}
          options={options}
          children={children}
        />
      )}
    </Modal>
  );
};

export default FormModal;
