import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  UilRocket,
  UilUser,
  UilLock,
  UilEye,
  UilEyeSlash,
} from '@iconscout/react-unicons';

import useAxios from '../../../hooks/useAxios';
import axiosInstance from '../../../util/apis';

import { authActions } from '../../../store/auth';

import classes from './Signin.module.css';
import commonClasses from '../../../util/common.module.css';

const Signin = () => {
  // Use axios hook
  const {
    error: axiosError,
    loading,
    completed,
    sendRequest,
    reset: resetAxios,
  } = useAxios();

  // Create state for form submitting
  const [formSubmit, setFormSubmit] = useState(false);

  // Create state for input fields
  const [usernameInput, setUsernameInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  // Create state for showing password
  const [showPassword, setShowPassword] = useState(false);

  // Reference to password input
  const passwordRef = useRef();

  // Reference to username input
  const usernameRef = useRef();

  // Create state for errors
  const [inputError, setInputError] = useState('');

  // Use dispatch
  const dispatch = useDispatch();

  // Use navigate
  const navigate = useNavigate();

  // Calculate whether submit button should be disabled
  const submitDisabled = completed || loading || formSubmit;

  // Handle upload request complete
  useEffect(() => {
    if (!completed) return;

    if (!axiosError) {
      // Reset axios states
      resetAxios();

      // Set global state to authenticated
      dispatch(authActions.signin());

      // Navgiate to /auth
      navigate('/auth', { replace: true });
    } else {
      // Generate error message
      const msg =
        axiosError ||
        'An error occurred while signing in. Please try again later.';

      // Reset axios states
      resetAxios();

      // Set error message
      setInputError(msg);

      // Allow submit again
      setFormSubmit(false);
    }
  }, [completed, axiosError, resetAxios, dispatch, navigate]);

  // Handle form ready to submit
  useEffect(() => {
    // If not submitting, has error, or active axios, do nothing
    if (!formSubmit || inputError || loading || completed) {
      return;
    }

    // Send request to backend
    sendRequest({
      axiosInstance,
      url: '/auth',
      method: 'POST',
      data: { username: usernameInput.toLowerCase(), password: passwordInput },
    });

    // Reset form submit state
    setFormSubmit(false);
  }, [
    formSubmit,
    inputError,
    loading,
    completed,
    sendRequest,
    usernameInput,
    passwordInput,
  ]);

  // On submit button click, validate inputs
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (submitDisabled) return;

    // Create a temporary error var
    let tempError = '';

    // Validate username input
    if (usernameInput.trim() === '') {
      tempError = 'Username cannot be empty';
    }

    // Validate password input
    if (passwordInput.trim() === '') {
      tempError = 'Password cannot be empty';
    }

    // Set the error state
    setInputError(tempError);

    // If no errors, set submit state to true
    if (!tempError) {
      setFormSubmit(true);
    }
  };

  // Toggle show password state
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Focus on input field
  const focusInput = (input) => {
    if (input === 'username') {
      usernameRef?.current.focus();
    } else {
      passwordRef?.current.focus();
    }
  };

  return (
    <form className={classes['form__container']} onSubmit={formSubmitHandler}>
      {/* Header */}
      <div className={classes['header__container']}>
        <div className={classes['header_icon__container']}>
          <UilRocket className={classes['icon']} />
        </div>
        <p className={commonClasses['no-select']}>Admin</p>
      </div>

      {/* Username Input Container */}
      <div
        className={classes['input__container']}
        onClick={focusInput.bind(null, 'username')}
      >
        <UilUser className={`${classes['icon']} ${classes['input__icon']}`} />
        <input
          ref={usernameRef}
          type='text'
          className={'overwrite-style'}
          placeholder='Username'
          value={usernameInput}
          onChange={(e) => {
            setInputError('');
            setUsernameInput(e.target.value);
          }}
        />
      </div>

      {/* Password Input Container */}
      <div
        className={classes['input__container']}
        onClick={focusInput.bind(null, 'password')}
      >
        <UilLock className={`${classes['icon']} ${classes['input__icon']}`} />
        <input
          ref={passwordRef}
          type={showPassword ? 'text' : 'password'}
          className={'overwrite-style'}
          placeholder='Password'
          value={passwordInput}
          onChange={(e) => {
            setInputError('');
            setPasswordInput(e.target.value);
          }}
        />
        {showPassword ? (
          <div
            className={classes['icon__wrapper']}
            onClick={toggleShowPassword}
          >
            <UilEyeSlash
              className={`${classes['icon']} ${classes['pw__icon']}`}
            />
          </div>
        ) : (
          <div
            className={classes['icon__wrapper']}
            onClick={toggleShowPassword}
          >
            <UilEye className={`${classes['icon']} ${classes['pw__icon']}`} />
          </div>
        )}
      </div>

      {inputError && (
        <p className={classes['error__text']}>{`* ${inputError} *`}</p>
      )}

      <button
        type='submit'
        className={`${classes['submit__btn']} ${
          submitDisabled ? classes['submit__btn_disabled'] : ''
        }`}
      >
        Sign In
      </button>
    </form>
  );
};

export default Signin;
