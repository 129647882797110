import { createSlice } from '@reduxjs/toolkit';

const initialEditState = {
  reloadEditModal: 0,
  reloadMainContent: 0,
  reloadAbout: 0,
  activeToolbar: '',
  toolbarAction: '',
  selectedImgs: [],
  imgToUpdate: null,
  toolbarDisabled: false,
};

/* 
  TOOLBAR ACTIONS
    - delete
    - delete-confirm
    - update
    - upload
    - arrange
*/

const editSlice = createSlice({
  name: 'edit',
  initialState: initialEditState,
  reducers: {
    reloadEditModal(state) {
      state.reloadEditModal = state.reloadEditModal + 1;
    },
    reloadMainContent(state) {
      state.reloadMainContent = state.reloadMainContent + 1;
    },
    reloadAbout(state) {
      state.reloadAbout = state.reloadAbout + 1;
    },
    disableToolbar(state) {
      state.toolbarDisabled = true;
    },
    enableToolbar(state) {
      state.toolbarDisabled = false;
    },

    setActiveToolbar(state, action) {
      state.activeToolbar = action.payload;
    },
    clearActiveToolbar(state) {
      state.activeToolbar = '';
    },
    setToolbarAction(state, action) {
      state.toolbarAction = action.payload;
    },
    clearToolbarAction(state) {
      state.toolbarAction = '';
    },
    addSelectedImgs(state, action) {
      const idToAdd = action.payload;

      state.selectedImgs = [...state.selectedImgs, idToAdd];
    },
    removeSelectedImgs(state, action) {
      const idToRemove = action.payload;

      const index = state.selectedImgs.findIndex((el) => el === idToRemove);

      if (index >= 0) {
        const tempSelectedImgs = [...state.selectedImgs];

        tempSelectedImgs.splice(index, 1);

        state.selectedImgs = tempSelectedImgs;
      }
    },
    clearSelectedImgs(state) {
      state.selectedImgs = [];
    },
    setImgToUpdate(state, action) {
      state.imgToUpdate = action.payload;
    },
    clearImgToUpdate(state) {
      state.imgToUpdate = null;
    },
    clearToolbarStates(state) {
      state.activeToolbar = '';
      state.toolbarAction = '';
      state.selectedImgs = [];
      state.imgToUpdate = null;
    },
    reset(state) {
      state.activeToolbar = '';
      state.toolbarAction = '';
      state.selectedImgs = [];
      state.toolbarDisabled = false;
    },
  },
});

export const editActions = editSlice.actions;

export default editSlice.reducer;
