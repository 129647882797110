import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  UilInstagram,
  UilTwitter,
  UilFacebookF,
  UilLinkedin,
  UilSnapchatGhost,
  UilTumblr,
} from '@iconscout/react-unicons';

import useAxios from '../../hooks/useAxios';
import axiosInstance from '../../util/apis';

import { contentActions } from '../../store/content';

import classes from './Footer.module.css';

/* 
Instagram
Twitter
Facebook
Linkedin
Snapchat
Tumblr
*/

const getJSX = (name) => {
  if (name === 'Instagram')
    return <UilInstagram className={classes['footer-icon']} />;
  if (name === 'Twitter')
    return <UilTwitter className={classes['footer-icon']} />;
  if (name === 'Facebook')
    return <UilFacebookF className={classes['footer-icon']} />;
  if (name === 'Linkedin')
    return <UilLinkedin className={classes['footer-icon']} />;
  if (name === 'Snapchat')
    return <UilSnapchatGhost className={classes['footer-icon']} />;
  return <UilTumblr className={classes['footer-icon']} />;
};

const Footer = () => {
  // Use Axios
  const {
    error,
    completed,
    response,
    sendRequest,
    reset: resetAxios,
  } = useAxios();

  // Get user info state
  const socialMedia = useSelector((state) => state.content.socialMedia);

  // Use dispatch
  const dispatch = useDispatch();

  // On component load, fetch user info
  useEffect(() => {
    sendRequest({
      axiosInstance,
      url: '/social-media',
      method: 'GET',
    });
  }, [sendRequest, resetAxios]);

  // Handle GET request complete
  useEffect(() => {
    if (!completed) return;

    // If no error, set global state
    if (!error) {
      dispatch(contentActions.setSocialMedia(response));
    }
  }, [error, completed, response, resetAxios, dispatch]);

  return (
    <footer className={classes['container']}>
      {socialMedia.map((data) => {
        if (!data.use) return null;

        return (
          <a
            href={data.url}
            target='_blank'
            rel='noreferrer'
            className={classes['icon-wrapper']}
            key={data.id}
          >
            {getJSX(data.name)}
          </a>
        );
      })}
    </footer>
  );
};

export default Footer;
