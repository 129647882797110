import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import { uploadTempImage, RESOURCE_URL } from '../../../util/apis';

import './TextEditor.css';
import commonCss from '../../../util/common.module.css';

const TextEditor = ({
  onEditorReady,
  onChange,
  data,
  isDisabled = false,
  allowImageUpload = false,
}) => {
  const changeHandler = (event, editor) => {
    onChange(event, editor);
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();

          loader.file.then((file) => {
            body.append('file', file);

            uploadTempImage(body)
              .then((res) => {
                const imageName = res;

                resolve({ default: `${RESOURCE_URL}/${imageName}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div
      className={`editor-container ${commonCss['custom_scrollbar']} ${commonCss['custom_scrollbar__dark']}`}
    >
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin],
          toolbar: [
            'heading',
            '|',
            'fontSize',
            'fontColor',
            'fontFamily',
            '|',
            'bold',
            'italic',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            'horizontalLine',
            '|',
            allowImageUpload ? 'imageUpload' : '',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
        }}
        data={data}
        editor={Editor}
        onReady={onEditorReady}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
        onChange={changeHandler}
        disabled={isDisabled}
      />
    </div>
  );
};

export default TextEditor;
