import { useState, useCallback } from 'react';

const useAxios = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const sendRequest = useCallback(async (config) => {
    const { axiosInstance, method, url, data = {} } = config;

    try {
      setLoading(true);

      const res = await axiosInstance[method.toLowerCase()](url, data);

      setResponse(res.data);
    } catch (err) {
      setError(err.response.data.message);
    } finally {
      setLoading(false);
      setCompleted(true);
    }
  }, []);

  const reset = useCallback(() => {
    setResponse(null);
    setError('');
    setLoading(false);
    setCompleted(false);
  }, []);

  return { response, error, loading, completed, sendRequest, reset };
};

export default useAxios;
