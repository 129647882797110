import { useDispatch, useSelector } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  UilEdit,
  UilTrash,
  UilGripHorizontalLine,
} from '@iconscout/react-unicons';

import { editActions } from '../../../../store/edit';

import commonClasses from '../../../../util/common.module.css';
import classes from './ImageCards.module.css';
import { Fragment } from 'react';

const ImageCard = ({
  data,
  id,
  index,
  activeToolbar,
  isAbout,
  disableBtns,
}) => {
  // Data for image
  let url, desc, additionalDesc, additionalDescContent;

  if (!isAbout) {
    url = data.url;
    desc = data.desc;
    additionalDesc = data.additionalDesc;
    additionalDescContent = data.additionalDescContent;
  }

  // Data for tab
  let tabName, tabContent;
  if (isAbout) {
    tabName = data.name;
    tabContent = data.content;
  }

  // State for all selected images
  const selectedImgs = useSelector((state) => state.edit.selectedImgs);

  // Calculate whether the current image card is selected
  const selected = selectedImgs?.some((imgId) => imgId === id);

  // Use dispatch
  const dispatch = useDispatch();

  // Handle on image card click
  const imageCardClickHandler = () => {
    // If not in select mode, do nothing
    if (activeToolbar !== 'select') return;

    // If previously selected, remove itself from selected imgs state
    if (selected) {
      dispatch(editActions.removeSelectedImgs(id));
    }
    // If not previously selected, add itself to selected imgs state
    else {
      dispatch(editActions.addSelectedImgs(id));
    }
  };

  // Handle edit button click
  const editIconHandler = () => {
    if (disableBtns) return;

    // If editting About, set the tab data
    if (isAbout) {
      dispatch(
        editActions.setImgToUpdate({ id, name: tabName, content: tabContent })
      );
    }
    // If not editting About, set the img to update
    else {
      dispatch(
        editActions.setImgToUpdate({
          id,
          url,
          desc,
          additionalDescContent,
          additionalDesc,
        })
      );
    }
    dispatch(editActions.setToolbarAction('update'));
  };

  // Handle delete button click
  const deleteIconHandler = () => {
    if (disableBtns) return;

    dispatch(editActions.addSelectedImgs(id));
    dispatch(editActions.setToolbarAction('delete-confirm'));
  };

  // Css class for the image card item
  const imgCardClass = `${classes['img-item__container']} ${
    activeToolbar === 'select' ? classes['selectable'] : ''
  } ${selected ? classes['img-item__container_selected'] : ''}`;

  // Css class for the select icon
  const selectIconClass = `${classes['icon']} ${classes['icon__select']} ${
    selected ? classes['icon__select_active'] : ''
  }`;

  return (
    <Draggable
      draggableId={id}
      key={id}
      index={index}
      isDragDisabled={activeToolbar !== 'arrange'}
    >
      {(provided) => (
        <div
          className={imgCardClass}
          onClick={imageCardClickHandler}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div className={classes['index__container']}>
            <p className={commonClasses['no-select']}>{index + 1}</p>
          </div>

          {/* Image Container */}
          {!isAbout && (
            <div className={classes['img__container']}>
              <img
                src={url}
                alt={desc}
                className={commonClasses['no-select']}
              />
            </div>
          )}

          {/* About Tabs Container */}
          {isAbout && (
            <div
              className={`${classes['img__container']} ${classes['tab__container']}`}
            >
              <p className={commonClasses['no-select']}>{tabName}</p>
            </div>
          )}

          {/* Image Card Icons */}
          <div className={classes['edit__container']}>
            {/* Arrange Mode */}
            {activeToolbar === 'arrange' && (
              <div className={classes['icon__wrapper']}>
                <UilGripHorizontalLine
                  className={`${classes['icon']} ${classes['icon__arrange']}`}
                />
              </div>
            )}

            {/* Edit Mode */}
            {activeToolbar === '' && (
              <Fragment>
                <div
                  className={classes['icon__wrapper']}
                  onClick={editIconHandler}
                >
                  <UilEdit
                    className={`${classes['icon']} ${classes['icon__edit']}`}
                  />
                </div>
                <div
                  className={classes['icon__wrapper']}
                  onClick={deleteIconHandler}
                >
                  <UilTrash
                    className={`${classes['icon']} ${classes['icon__delete']}`}
                  />
                </div>
              </Fragment>
            )}

            {/* Select Mode */}
            {activeToolbar === 'select' && (
              <div className={classes['icon__wrapper']}>
                <div className={selectIconClass}></div>
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

const ImageCards = ({ imgData, activeToolbar, isAbout }) => {
  // State for toolbar disabled
  const toolbarDIsabled = useSelector((state) => state.edit.toolbarDisabled);

  return (
    <Droppable droppableId='groot'>
      {(provided) => (
        <div
          className={`${classes['img-list-container']} ${commonClasses['custom_scrollbar']} ${commonClasses['custom_scrollbar__dark']}`}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {imgData.map((data, index) => (
            <ImageCard
              activeToolbar={activeToolbar}
              disableBtns={toolbarDIsabled}
              isAbout={isAbout}
              data={
                isAbout
                  ? { name: data.name, content: data.content }
                  : {
                      url: data.url,
                      desc: data.desc,
                      additionalDesc: data.additionalDesc,
                      additionalDescContent: data.additionalDescContent,
                    }
              }
              key={data.id}
              id={data.id}
              index={index}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ImageCards;
