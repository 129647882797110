import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { UilTimes } from '@iconscout/react-unicons';

import { errorActions } from '../../../store/error';
import Modal from '../modal/Modal';
import { modalAnimationTiming } from '../../../util/config';

import modalClasses from '../modal/Modal.module.css';
import classes from './ErrorModal.module.css';
import { useCallback } from 'react';

const ModalOverlay = memo(({ show, closeModal, message, onModalExit }) => {
  return (
    <CSSTransition
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={{ enter: 250, exit: modalAnimationTiming }}
      classNames={{
        enter: '',
        enterActive: classes['overlay-slide-in'],
        exit: '',
        exitActive: classes['overlay-fadeout'],
        appear: '',
        appearActive: '',
      }}
      onExited={onModalExit}
    >
      <div className={classes['overlay']}>
        {/* Header */}
        <div className={classes['header__container']}>
          <p className={modalClasses['common-overlay__text']}>Error</p>
          <div
            className={modalClasses['common-icon__wrapper']}
            onClick={closeModal}
          >
            <UilTimes className={modalClasses['common-icon']} />
          </div>
        </div>

        {/* Content */}
        <div className={classes['content__container']}>
          <p>{message}</p>
        </div>

        {/* Button */}
        <div className={classes['btn__container']}>
          <button onClick={closeModal}>Ok</button>
        </div>
      </div>
    </CSSTransition>
  );
});

const ErrorModal = () => {
  const errorModalState = useSelector((state) => state.error.state);
  const errorMessage = useSelector((state) => state.error.message);

  const dispatch = useDispatch();

  const show = errorModalState === 'open';

  const closeModal = useCallback(() => {
    dispatch(errorActions.close());
  }, [dispatch]);

  const onModalExit = useCallback(() => {
    dispatch(errorActions.closed());
  }, [dispatch]);

  return (
    <Modal
      show={show}
      closeModal={closeModal}
      showCloseIcon={false}
      zIndex={20}
    >
      <ModalOverlay
        show={show}
        message={errorMessage}
        closeModal={closeModal}
        onModalExit={onModalExit}
      />
    </Modal>
  );
};

export default ErrorModal;
