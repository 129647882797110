import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import FormModal from '../../ui/formModal/FormModal';

import useAxios from '../../../hooks/useAxios';
import axiosInstance from '../../../util/apis';

import useLoadingModal from '../../../hooks/useLoadingModal';
import useErrorModal from '../../../hooks/useErrorModal';

import { formatTabName } from '../../../util/util';
import { editActions } from '../../../store/edit';

import classes from './EditDescModal.module.css';

const EditDescModal = ({ show, closeModal, currentTab, desc }) => {
  const {
    sendRequest,
    loading,
    completed,
    error,
    reset: resetAxios,
  } = useAxios();

  // State for description input
  const [descInput, setDescInput] = useState('');

  // State for submitting form
  const [formSubmit, setFormSubmit] = useState(false);

  // Use dispatch
  const dispatch = useDispatch();

  // Use loading modal
  const { openLoadingModal, finish: finishLoading } = useLoadingModal();

  // Use error modal
  const openErrorModal = useErrorModal();

  // // On first load, set description
  // useEffect(() => {
  //   setDescInput(desc);
  // }, [desc]);

  // Handle request complete
  useEffect(() => {
    if (!completed) return;

    let onLoadingComplete;

    // If no error
    if (!error) {
      onLoadingComplete = () => {
        resetAxios();
        setFormSubmit(false);
        closeModal();
        dispatch(editActions.reloadMainContent());
      };
    }
    // If error
    else {
      const msg =
        error || 'An error occurred while updating. Please try again later.';

      onLoadingComplete = () => {
        openErrorModal(msg, () => {
          resetAxios();
          setFormSubmit(false);
        });
      };
    }

    finishLoading({
      onComplete: onLoadingComplete,
      delay: 500,
    });
  }, [
    completed,
    error,
    finishLoading,
    openErrorModal,
    dispatch,
    closeModal,
    resetAxios,
  ]);

  // Handle form ready to submit
  useEffect(() => {
    // If not submitting form or active axios, do nothing
    if (!formSubmit || loading || completed) return;

    sendRequest({
      axiosInstance,
      url: '/tabs',
      method: 'PATCH',
      data: { tab: formatTabName(currentTab, true), desc: descInput },
    });

    openLoadingModal('Updating');
  }, [
    formSubmit,
    sendRequest,
    loading,
    completed,
    currentTab,
    descInput,
    openLoadingModal,
  ]);

  // Before closing the modal, reset the state
  const closeEditDescModal = useCallback(() => {
    setDescInput('');
    closeModal();
  }, [closeModal]);

  // If description hasn't changed, disabled upload button
  const disableUpload = completed || loading || descInput === desc;

  // When input is changed, save it to the state
  const inputChangeHandler = (e) => {
    setDescInput(e.target.value);
  };

  // Handle form submit
  const submitHandler = useCallback(() => {
    // If no change detected, do nothing
    if (disableUpload) return;

    setFormSubmit(true);
  }, [disableUpload]);

  // Form modal options
  const modalOptions = {
    buttons: {
      confirm: {
        name: 'Update',
        isDisabled: disableUpload,
        onClick: submitHandler,
      },
      cancel: {
        onClick: closeEditDescModal,
      },
    },
    title: 'Description',
    onEntered: () => {
      setDescInput(desc);
    },
  };

  return (
    <FormModal
      show={show}
      closeModal={closeEditDescModal}
      options={modalOptions}
    >
      {/* Content */}
      <form className={classes['content__container']}>
        <label htmlFor='tab-desc'>Description</label>
        <input
          id='tab-desc'
          type='text'
          placeholder='Enter a description for this tab...'
          value={descInput}
          onChange={inputChangeHandler}
        />
      </form>
    </FormModal>
  );
};

export default EditDescModal;
