import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cropper from 'react-easy-crop';

import FormModal from '../../ui/formModal/FormModal';

import useAxios from '../../../hooks/useAxios';
import axiosInstance from '../../../util/apis';

import useLoadingModal from '../../../hooks/useLoadingModal';
import useErrorModal from '../../../hooks/useErrorModal';

import { editActions } from '../../../store/edit';

import classes from './EditPhotoModal.module.css';
import uploadClasses from '../imageUploadModal/ImageUploadModal.module.css';

const EditPhotoModal = ({ show, closeModal }) => {
  // Use axios hook
  const {
    error,
    loading,
    completed,
    sendRequest,
    reset: resetAxios,
  } = useAxios();

  // Use loading modal
  const { openLoadingModal, finish: finishLoading } = useLoadingModal();

  // Use error modal
  const openErrorModal = useErrorModal();

  // Use dispatch
  const dispatch = useDispatch();

  // Create state for image data
  const [imgData, setImgData] = useState({ url: null, file: null });

  // Create state for Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedData, setCroppedData] = useState(null);

  // Create state for can upload
  const [canUpload, setCanUpload] = useState(true);

  // Reset all states to their initial values
  const resetStates = () => {
    setImgData({ url: null, file: null });
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedData(null);
    setCanUpload(true);
  };

  // Handle request complete
  useEffect(() => {
    if (!completed) return;

    // If no error, close modal
    if (!error) {
      resetAxios();

      const onLoadingComplete = () => {
        closeModal();
        dispatch(editActions.reloadAbout());
      };

      finishLoading({ onComplete: onLoadingComplete, delay: 500 });
    }
    // If error, open error modal
    else {
      // Generate error message
      const msg =
        error || 'An error occurred while uploading. Please try again.';

      resetAxios();

      const onLoadingComplete = () => {
        openErrorModal(msg, () => {
          setCanUpload(true);
        });
      };

      finishLoading({ onComplete: onLoadingComplete, delay: 500 });
    }
  }, [
    dispatch,
    completed,
    error,
    resetAxios,
    openErrorModal,
    finishLoading,
    closeModal,
    canUpload,
  ]);

  // When new image selected, update the preview and save the file
  const imgSelectHandler = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const file = files[0];

      setZoom(1);
      setImgData({ url: URL.createObjectURL(file), file });
    }
  };

  // On crop complete, set crop data to state
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedData(croppedAreaPixels);
  };

  // On zoom slider change, set value to state
  const zoomInputHandler = (e) => {
    const value = e.target.value;

    setZoom(value);
  };

  const isUploadDisabled = () => {
    // If cannot upload
    if (!canUpload) return true;

    // If no image data or crop data
    if (!imgData.file || !croppedData) return true;

    // If active axios
    if (loading || completed) return true;

    return false;
  };

  // Handle form submit
  const submitForm = () => {
    if (isUploadDisabled()) return;

    const formData = new FormData();
    formData.append('file', imgData.file);
    formData.append('width', croppedData.width);
    formData.append('height', croppedData.height);
    formData.append('left', croppedData.x);
    formData.append('top', croppedData.y);

    sendRequest({
      axiosInstance,
      url: '/users/photo',
      method: 'PATCH',
      data: formData,
    });

    openLoadingModal('Updating');

    setCanUpload(false);
  };

  // Form modal options
  const modalOptions = {
    disableButtons: true,
    title: 'Photo',
    headerColor: 'var(--color-green)',
    style: { maxWidth: '48rem' },
    zIndex: 7,
    onExited: resetStates,
  };

  return (
    <FormModal show={show} closeModal={closeModal} options={modalOptions}>
      {/* Main Content */}
      <form
        className={uploadClasses['form__container']}
        encType='multipart/form-data'
      >
        {/* Image Preview */}
        <div className={uploadClasses['img-preview__container']}>
          <p className={`label ${uploadClasses['preview__label']}`}>Preview</p>

          <div
            className={`${uploadClasses['img__wrapper']} ${classes['cropper__container']}`}
          >
            {imgData.url && (
              <Cropper
                image={imgData.url}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>
        </div>

        {/* Sliders */}
        <div className={classes['slider__container']}>
          <div className={classes['slider_label__container']}>
            <label>Zoom: </label>
            <label>{`${Math.round(zoom * 100) / 100}x`}</label>
          </div>
          <input
            type='range'
            min='1'
            max='3'
            step='0.02'
            value={zoom}
            onInput={zoomInputHandler}
          />
        </div>

        {/* Action Buttons */}
        <div className={`${uploadClasses['btns__container']} `}>
          <div className={uploadClasses['action-btns__container']}>
            <button
              className={`${uploadClasses['btn']} ${uploadClasses['btn-choose']}`}
              type='button'
            >
              Choose Photo
              <input
                type='file'
                accept='image/*'
                id='upload-image'
                onChange={imgSelectHandler}
              />
              <label
                htmlFor='upload-image'
                className={uploadClasses['upload-img__label']}
              ></label>
            </button>
          </div>

          <div className={uploadClasses['action-btns__container']}>
            <button
              className={`${uploadClasses['btn']} ${
                uploadClasses['btn-upload']
              } ${
                isUploadDisabled() ? uploadClasses['btn-upload__disabled'] : ''
              }`}
              type='button'
              onClick={submitForm}
            >
              {'Update'}
            </button>
            <button
              className={`${uploadClasses['btn']} ${uploadClasses['btn-cancel']}`}
              type='button'
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </FormModal>
  );
};

export default EditPhotoModal;
