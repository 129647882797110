import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import TextEditor from '../../../ui/textEditor/TextEditor';
import FormModal from '../../../ui/formModal/FormModal';

import useLoadingModal from '../../../../hooks/useLoadingModal';
import useErrorModal from '../../../../hooks/useErrorModal';

import useAxios from '../../../../hooks/useAxios';
import axiosInstance from '../../../../util/apis';

import { editActions } from '../../../../store/edit';

import classes from './EditTabsContentModal.module.css';
import { useCallback } from 'react';

const EditTabsContentModal = ({ show, closeModal, tabsData }) => {
  // Use axios hook for POST / PATCH
  const { error, completed, sendRequest, reset: resetAxios } = useAxios();

  // Create state for loading modal
  const { openLoadingModal, finish: finishLoading } = useLoadingModal();

  // Use error modal
  const openErrorModal = useErrorModal();

  // Create state for tab content
  const [content, setContent] = useState('');

  // Create state for tab name
  const [tabName, setTabName] = useState('');

  // Create state for can submit
  const [canSubmit, setCanSubmit] = useState(true);

  // Create state for submitted
  const [submitSuccess, setSubmitSuccess] = useState(false);

  // Use dispatch
  const dispatch = useDispatch();

  // Clear the content before exit
  const closeEditTabsContentModal = useCallback(() => {
    setContent('');
    setTabName('');
    closeModal();
  }, [closeModal]);

  // On first render, if updating, set initial state
  useEffect(() => {
    if (tabsData) {
      setTabName(tabsData.name);
      setContent(tabsData.content);
    }

    setCanSubmit(true);
  }, [tabsData]);

  // Handle requset complete
  useEffect(() => {
    if (!completed) return;

    // If no error, finish loading, exit
    if (!error) {
      resetAxios();

      const onLoadingComplete = () => {
        setSubmitSuccess(true);
        closeEditTabsContentModal();
      };

      finishLoading({
        onComplete: onLoadingComplete,
        delay: 500,
      });
    }
    // If error, show error modal
    else {
      // Generate error message
      const msg =
        error || 'An error occurred while uploading. Please try again.';

      resetAxios();

      const onLoadingComplete = () => {
        openErrorModal(msg, () => {
          setCanSubmit(true);
        });
      };

      finishLoading({
        onComplete: onLoadingComplete,
        delay: 500,
      });
    }
  }, [
    error,
    completed,
    resetAxios,
    finishLoading,
    openErrorModal,
    closeEditTabsContentModal,
  ]);

  // When tab is pressed, prevent it from going to the next element
  const preventTab = (e) => {
    if (e.which === 9) e.preventDefault();
  };

  // On editor content change, set it to state
  const onEditorChange = (event, editor) => {
    setContent(editor.getData());
  };

  // If name is empty
  const tabNameEmpty = !tabName || tabName.trim() === '';

  // On submit button pressed, submit the request
  const submitBtnHandler = () => {
    if (!canSubmit || tabNameEmpty) return;

    // If updating existing tab
    if (tabsData) {
      sendRequest({
        axiosInstance,
        url: `/about-tabs/${tabsData.id}`,
        method: 'PATCH',
        data: { name: tabName, content },
      });
    }
    // If creating new tab
    else {
      sendRequest({
        axiosInstance,
        url: '/about-tabs',
        method: 'POST',
        data: { name: tabName, content },
      });
    }

    openLoadingModal(tabsData ? 'Updating' : 'Uploading');

    setCanSubmit(false);
  };

  // Modal options for FormModal
  const modalOptions = {
    title: 'Tabs',
    zIndex: 7,
    disableBackdropClose: true,
    buttons: {
      confirm: {
        name: tabsData ? 'Update' : 'Add',
        onClick: submitBtnHandler,
        isDisabled: !canSubmit || tabNameEmpty,
      },
    },
    onExited: () => {
      if (submitSuccess) {
        dispatch(editActions.reloadEditModal());
      }
    },
    headerColor: 'var(--color-green)',
  };

  return (
    <FormModal
      show={show}
      closeModal={closeEditTabsContentModal}
      options={modalOptions}
    >
      <form className={classes['form__container']}>
        {/* Tab Name */}
        <div className={classes['input__container']}>
          <label htmlFor='tab-name'>Tab Name</label>
          <input
            id='tab-name'
            type='text'
            required
            value={tabName}
            onChange={(e) => {
              setTabName(e.target.value);
            }}
          />
        </div>

        {/* Text Editor */}
        <div
          className={`${classes['input__container']} ${classes['editor__container']}`}
          onKeyDown={preventTab}
        >
          <label>Tab Content</label>
          <TextEditor onChange={onEditorChange} data={content} />
        </div>
      </form>
    </FormModal>
  );
};

export default EditTabsContentModal;
