import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  UilApps,
  UilFilePlus,
  UilFocus,
  UilTrash,
  UilSave,
} from '@iconscout/react-unicons';

import { editActions } from '../../../../store/edit';

import commonClasses from '../../../../util/common.module.css';
import classes from './ImageToolbar.module.css';

const ImageToolbar = ({ onArrangeClick, disableArrangeSave }) => {
  // State of the currently selected toolbar
  const activeToolbar = useSelector((state) => state.edit.activeToolbar);

  // State of current selected images
  const selectedImgs = useSelector((state) => state.edit.selectedImgs);

  // State of current disable toolbars
  const toolbarDisabled = useSelector((state) => state.edit.toolbarDisabled);

  // Use diaptch
  const dispatch = useDispatch();

  // Wether the delete and save buttons are disabled
  const deleteDisabled = toolbarDisabled || selectedImgs.length === 0;
  const saveDisabled = toolbarDisabled || disableArrangeSave;

  // On icon click, set current selected toolbar state
  const onToolbarClick = (name) => {
    // If toolbars disabled, do nothing
    if (toolbarDisabled) return;

    // If selected delete
    if (name === 'delete') {
      if (deleteDisabled) return;

      dispatch(editActions.setToolbarAction('delete-confirm'));

      return;
    }

    // Clear selected images
    dispatch(editActions.clearSelectedImgs());

    // If selected add
    if (name === 'upload') {
      dispatch(editActions.setToolbarAction('upload'));

      return;
    }

    // If selected save
    if (name === 'save') {
      if (saveDisabled) return;

      dispatch(editActions.setToolbarAction('save'));

      return;
    }

    // If selecting a different toolbar, select the chosen toolbar
    if (activeToolbar !== name) {
      dispatch(editActions.setActiveToolbar(name));
    }
    // If select the same toolbar, deselect the current toolbar
    else {
      dispatch(editActions.clearActiveToolbar());
    }
  };

  // Css classes
  const disableToolbarsClass = toolbarDisabled
    ? classes['icon__wrapper__disabled']
    : '';

  const deleteIconWrapperClass = `${classes['icon__wrapper']} ${
    classes['icon__wrapper__delete']
  } ${deleteDisabled ? classes['icon__wrapper__disabled'] : ''}`;

  const saveIconWrapperClass = `${classes['icon__wrapper']} ${
    classes['icon__wrapper__save']
  } ${saveDisabled ? classes['icon__wrapper__disabled'] : ''}`;

  const selectIconWrapperClass = `${classes['icon__wrapper']} ${
    classes['icon__wrapper__select']
  } ${
    activeToolbar === 'select' ? classes['icon__wrapper__select_active'] : ''
  } ${disableToolbarsClass}`;

  const arrangeIconWrapperClass = `${classes['icon__wrapper']} ${
    classes['icon__wrapper__arrange']
  } ${
    activeToolbar === 'arrange' ? classes['icon__wrapper__arrange_active'] : ''
  } ${disableToolbarsClass}`;

  const addIconWrapperClass = `${classes['icon__wrapper']} ${classes['icon__wrapper__add']} ${disableToolbarsClass}`;

  return (
    <Fragment>
      <ul className={classes['toolbar__container']}>
        {/* Delete */}
        {activeToolbar === 'select' && (
          <li
            className={deleteIconWrapperClass}
            onClick={onToolbarClick.bind(null, 'delete')}
          >
            <UilTrash className={`${classes['icon']}`} />{' '}
            <span
              className={`${classes['tooltip']} ${commonClasses['tooltip']}`}
            >
              Delete
            </span>
          </li>
        )}

        {/* Save Arrange */}
        {activeToolbar === 'arrange' && (
          <li
            className={saveIconWrapperClass}
            onClick={onToolbarClick.bind(null, 'save')}
          >
            <UilSave className={`${classes['icon']}`} />{' '}
            <span
              className={`${classes['tooltip']} ${commonClasses['tooltip']}`}
            >
              Save
            </span>
          </li>
        )}

        {/* Select */}
        <li
          className={selectIconWrapperClass}
          onClick={onToolbarClick.bind(null, 'select')}
        >
          <UilFocus className={`${classes['icon']} `} />
          <span className={`${classes['tooltip']} ${commonClasses['tooltip']}`}>
            Select
          </span>
        </li>

        {/* Arrange */}
        <li
          className={arrangeIconWrapperClass}
          onClick={() => {
            onToolbarClick('arrange');
            onArrangeClick();
          }}
        >
          <UilApps className={`${classes['icon']} `} />
          <span className={`${classes['tooltip']} ${commonClasses['tooltip']}`}>
            Arrange
          </span>
        </li>

        {/* Add */}
        <li
          className={addIconWrapperClass}
          onClick={onToolbarClick.bind(null, 'upload')}
        >
          <UilFilePlus className={`${classes['icon']} `} />
          <span className={`${classes['tooltip']} ${commonClasses['tooltip']}`}>
            New
          </span>
        </li>
      </ul>
    </Fragment>
  );
};

export default ImageToolbar;
