import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  UilInstagram,
  UilTwitter,
  UilFacebookF,
  UilLinkedin,
  UilSnapchatGhost,
  UilTumblr,
} from '@iconscout/react-unicons';

import FormModal from '../../../ui/formModal/FormModal';

import useErrorModal from '../../../../hooks/useErrorModal';
import useLoadingModal from '../../../../hooks/useLoadingModal';

import useAxios from '../../../../hooks/useAxios';
import axiosInstance from '../../../../util/apis';

import { contentActions } from '../../../../store/content';

import classes from './EditSocialMediaModal.module.css';

const IconWrapper = ({ name }) => {
  const getJSX = (name) => {
    if (name === 'Instagram')
      return <UilInstagram className={classes['icon']} />;
    if (name === 'Twitter') return <UilTwitter className={classes['icon']} />;
    if (name === 'Facebook')
      return <UilFacebookF className={classes['icon']} />;
    if (name === 'Linkedin') return <UilLinkedin className={classes['icon']} />;
    if (name === 'Snapchat')
      return <UilSnapchatGhost className={classes['icon']} />;
    return <UilTumblr className={classes['icon']} />;
  };

  return <div className={classes['icon__wrapper']}>{getJSX(name)}</div>;
};

const Field = ({ name, url, use, setState }) => {
  const urlChangeHandler = (e) => {
    setState((prev) => {
      const newState = { ...prev };
      newState[name].url = e.target.value;

      return newState;
    });
  };

  const useChangeHandler = () => {
    setState((prev) => {
      const newState = { ...prev };
      newState[name].use = !newState[name].use;

      return newState;
    });
  };

  return (
    <div className={classes['field__container']}>
      <div className={classes['label__container']}>
        <IconWrapper name={name} />
        <label className={classes['input__label']}>{name}</label>
      </div>

      <div className={classes['input__container']}>
        <input
          type='text'
          className={`${classes['url__input']} ${
            use ? '' : classes['url__input_disabled']
          }`}
          placeholder='https://socialmedia.com...'
          value={url}
          onChange={urlChangeHandler}
          disabled={!use}
        />
        <div className={`${classes['checkbox__container']}`}>
          <input
            id={`${name}-checkbox`}
            type='checkbox'
            className={classes['checkbox']}
            checked={use}
            onChange={useChangeHandler}
          />
          <label
            htmlFor={`${name}-checkbox`}
            className={`overwrite-style ${classes['checkbox__label']}`}
          ></label>
        </div>
      </div>
    </div>
  );
};

const EditSocialMediaModal = ({ show, closeModal }) => {
  // Use user axios hook
  const {
    error,
    response,
    completed,
    loading,
    sendRequest,
    reset: resetAxios,
  } = useAxios();

  // Use dispatch
  const dispatch = useDispatch();

  // Create state for loading modal
  const { openLoadingModal, finish: finishLoading } = useLoadingModal();

  // Use error modal
  const openErrorModal = useErrorModal();

  // Global state for name and title
  const socialMedia = useSelector((state) => state.content.socialMedia);

  // Create state for the inputs
  const [inputStates, setInputStates] = useState([]);

  // Create state for can submit
  const [canSubmit, setCanSubmit] = useState(true);

  // Set all inputs to the values from state
  const setInputs = useCallback(() => {
    // Create an empty object
    const statesObj = {};

    // Map the social media array into an object
    socialMedia.forEach((data) => {
      statesObj[data.name] = { name: data.name, url: data.url, use: data.use };
    });

    // Set the state
    setInputStates(statesObj);
  }, [socialMedia]);

  // Clear all inputs
  const clearInputs = () => {
    setInputStates((prev) => {
      const newState = { ...prev };

      // Clear the state fields
      for (const name of Object.keys(newState)) {
        newState[name].url = '';
        newState[name].use = false;
      }

      return newState;
    });
  };

  // Calculate whether submit button is disabled
  const disableUpdate = loading || completed || !canSubmit;

  // Handle request complete
  useEffect(() => {
    if (!completed || canSubmit) return;

    // If no error and got a response
    if (!error && response) {
      resetAxios();

      const onLoadingComplete = () => {
        closeModal();
        dispatch(contentActions.setSocialMedia(response));
      };

      finishLoading({ onComplete: onLoadingComplete, delay: 500 });
    }
    // If error
    else {
      // Construct error message
      const msg = error || 'An error occurred during saving. Please try again.';

      resetAxios();

      const onLoadingComplete = () => {
        openErrorModal(msg, () => {
          setCanSubmit(true);
        });
      };

      finishLoading({ onComplete: onLoadingComplete, delay: 500 });
    }
  }, [
    completed,
    error,
    response,
    resetAxios,
    openErrorModal,
    finishLoading,
    dispatch,
    canSubmit,
    closeModal,
  ]);

  // Handle submit button click
  const submitClickHandler = () => {
    // Turn the social media input states into array
    const data = Object.values(inputStates);

    // Send the request
    sendRequest({
      axiosInstance,
      url: '/social-media',
      method: 'PATCH',
      data: { socialMediaList: data },
    });

    // Open loading modal
    openLoadingModal('Saving');

    // Set canSubmit flag
    setCanSubmit(false);
  };

  // Form modal options
  const modalOptions = {
    title: 'Social Media',
    buttons: {
      confirm: {
        name: 'Save',
        isDisabled: disableUpdate,
        onClick: submitClickHandler,
      },
    },
    headerColor: 'var(--color-yellow)',
    onExited: clearInputs,
    onEntered: setInputs,
  };

  return (
    <FormModal show={show} closeModal={closeModal} options={modalOptions}>
      <form className={classes['form__container']}>
        {Object.values(inputStates).map((state, index) => (
          <Field
            name={state.name}
            url={state.url}
            use={state.use}
            setState={setInputStates}
            key={index}
          />
        ))}
      </form>
    </FormModal>
  );
};

export default EditSocialMediaModal;
