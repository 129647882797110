import { useState } from 'react';

const useConfirmationModal = (options) => {
  const modalOptions = {
    title: 'Confirm',
    confirmBtnText: 'Confirm',
    cancelBtnText: 'Cancel',
    message: 'Are you sure you want to proceed?',
    onClose: null,
    ...options,
  };

  const [isShowModal, setIsShowModal] = useState(false);

  const openModal = () => {
    setIsShowModal(true);
  };

  const closeModal = () => {
    modalOptions.onClose && modalOptions.onClose();

    setIsShowModal(false);
  };

  modalOptions.onConfirm = () => {
    options.onConfirm && options.onConfirm();
    closeModal();
  };

  modalOptions.onCancel = () => {
    options.onCancel && options.onCancel();
    closeModal();
  };

  return { isShowModal, openModal, closeModal, modalOptions };
};

export default useConfirmationModal;
