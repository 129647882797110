import { createSlice } from '@reduxjs/toolkit';

const inititalAuthState = {
  isAuthenticated: false,
  triedToAuthenticate: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: inititalAuthState,
  reducers: {
    tryToAuthenticate(state) {
      state.triedToAuthenticate = true;
    },
    signin(state) {
      state.isAuthenticated = true;
    },
    signout(state) {
      state.isAuthenticated = false;
      state.triedToAuthenticate = false;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
