export const modalAnimationTiming = 150;

export const deounceDelay = 300;

// values: dev, prod
// export const ENV = 'dev';
export const ENV = 'prod';

export const routes = {
  ENVIRONMENT: '/environment',
  CHARACTER_AND_COSTUME: '/characters-and-costume',
  PROP_DESIGN: '/prop-design',
  THREE_D: '/3d',
  PLEIN_AIR_AND_OTHERS: '/plein-air-and-others',
  ABOUT: '/about',
};

export const SOCIAL_MEDIA_LIST = [
  'Instagram',
  'Twitter',
  'Facebook',
  'Linkedin',
  'Snapchat',
  'Tumblr',
];

const LAYOUT_CONFIG = new Map([
  [-1, 0],
  [860, 1],
  [1440, 2],
  [1920, 3],
  [2500, 4],
  [Infinity, 4],
]);

export const getLayoutOptions = (windowWidth) => {
  for (const [refWidth, cols] of LAYOUT_CONFIG) {
    if (windowWidth < refWidth) return { columns: cols, gap: 16 };
  }
};

/* 
  - LAZY LOAD
  - STATE HOLD ALL S3 URL
    {
      URL
      EXPIRESIN
      RECEIVED_AT
    }
  - IMAGE BLOCK CHECK IF ID HAS S3 URL
  - LOAD FULL IMAGE ONLY IN MODAL
  - UI UPDATE?
*/
