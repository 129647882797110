import { useCallback, useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';

import FormModal from '../../ui/formModal/FormModal';
import TextEditorWrapper from './TextEditorWrapper';

import useLoadingModal from '../../../hooks/useLoadingModal';
import useErrorModal from '../../../hooks/useErrorModal';

import axiosInstance, { deleteTempUpload } from '../../../util/apis';
import useAxios from '../../../hooks/useAxios';

import { formatTabName } from '../../../util/util';
import { editActions } from '../../../store/edit';

import classes from './ImageUploadModal.module.css';
import commonClasses from '../../../util/common.module.css';

const ImageUploadModal = ({
  show,
  closeUploadModal,
  updateOptions,
  currentTab,
}) => {
  // Use axios hook
  const {
    response,
    error,
    loading,
    completed,
    sendRequest,
    reset: resetAxios,
  } = useAxios();

  // Create state for image data
  const [imgData, setImgData] = useState({ url: null, file: null });
  const [imgDesc, setImgDesc] = useState('');

  // Create state for submitting form
  const [formSubmit, setFormSubmit] = useState(false);

  // Create state for upload button
  const [canUpload, setCanUpload] = useState(true);

  // Create state for uploaded image data
  const [uploadedImageData, setUploadedImageData] = useState(false);

  // Create state for tab content
  const [editorContent, setEditorContent] = useState('');
  // Create state for showing editor
  const [showEditor, setShowEditor] = useState(false);

  // Use error modal
  const openErrorModal = useErrorModal();

  // Use dispatch
  const dispatch = useDispatch();

  // Reset the state before closing the modal
  const closeModal = useCallback(() => {
    setImgData({ url: null, file: null });
    setImgDesc('');
    setCanUpload(true);
    setFormSubmit(false);
    resetAxios();

    closeUploadModal();
  }, [closeUploadModal, resetAxios]);

  // Create state for loading modal
  const { openLoadingModal, finish: finishLoading } = useLoadingModal();

  // If updating an image, set the description
  useEffect(() => {
    if (updateOptions) {
      setImgDesc(updateOptions.desc);
      setShowEditor(updateOptions.additionalDesc);

      if (updateOptions.additionalDesc) {
        setEditorContent(updateOptions.additionalDescContent);
      }
    }
  }, [updateOptions]);

  // Handle request complete
  useEffect(() => {
    // If request not completed, do nothing
    if (!completed) return;

    // If no errors
    if (!error) {
      // Reset axios and form submit
      resetAxios();
      setFormSubmit(false);

      // Set the state for uploaded image data
      setUploadedImageData(true);

      // Finish loading animation, close modal after 0.5s
      finishLoading({
        onComplete: closeModal,
        delay: 500,
      });
    }
    // If errors
    else {
      // Generate error message
      const subject = updateOptions ? 'updating' : 'uploading';
      const message =
        error || `An error occurred while ${subject}. Please try again.`;

      // Reset axios so this useEffect doesn't trigger again
      resetAxios();

      // Finish loading animation, show error message
      const onLoadingComplete = () => {
        const onErrorModalClose = () => {
          // Reset form submit to allow for another upload
          setFormSubmit(false);

          // Enable upload button
          setCanUpload(true);
        };

        openErrorModal(message, onErrorModalClose);
      };

      finishLoading({
        onComplete: onLoadingComplete,
        delay: 500,
      });
    }
  }, [
    completed,
    response,
    resetAxios,
    finishLoading,
    error,
    closeModal,
    updateOptions,
    openErrorModal,
  ]);

  // Submit form when ready
  useEffect(() => {
    // If not submitting, or axios active, do nothing
    if (!formSubmit || loading || completed || !canUpload) return;

    // If updating an image
    if (updateOptions) {
      // Send request to backend
      sendRequest({
        axiosInstance,
        url: `/images/${updateOptions.id}`,
        method: 'PATCH',
        data: {
          desc: imgDesc,
          additionalDesc: showEditor,
          additionalDescContent: showEditor ? editorContent : '',
        },
      });

      // Open loading modal
      openLoadingModal();
    }
    // If uploading new image
    else {
      // Create new form data
      const formData = new FormData();

      // Append image data
      formData.append('desc', imgDesc);
      formData.append('additionalDesc', showEditor);
      formData.append('additionalDescContent', showEditor ? editorContent : '');
      formData.append('file', imgData.file);
      formData.append('tab', formatTabName(currentTab, true));

      // Send request to backend
      sendRequest({
        axiosInstance,
        url: '/images',
        method: 'POST',
        data: formData,
      });

      // Open loading modal
      openLoadingModal();
    }

    setCanUpload(false);
  }, [
    formSubmit,
    imgData,
    imgDesc,
    openLoadingModal,
    updateOptions,
    sendRequest,
    currentTab,
    completed,
    loading,
    canUpload,
    showEditor,
    editorContent,
  ]);

  // When new image selected, update the preview and save the file
  const imgSelectHandler = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const file = files[0];

      setImgData({ url: URL.createObjectURL(file), file });
    }
  };

  // Check whether the upload button should be disabled
  const isUploadDisabled = () => {
    if (!canUpload) return true;

    // If sending data to backend, disable upload button
    if (loading) return true;

    // In edit mode, if description is not changed, disable upload button
    if (updateOptions) {
      // return imgDesc === updateOptions.desc;
      return false;
    }

    // If no image selected or form is submitting, disable upload button
    return !imgData.url || !imgData.file || formSubmit;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (isUploadDisabled()) return;

    setFormSubmit(true);
  };

  // On editor content change, set it to state
  const onEditorChange = (event, editor) => {
    setEditorContent(editor.getData());
  };

  const modalOptions = {
    disableButtons: true,
    disableBackdropClose: true,
    zIndex: 7,
    title: updateOptions ? 'Update' : 'Upload',
    headerColor: 'var(--color-green)',
    style: { maxWidth: '120rem' },
    onExited: () => {
      // Delete temp upload images
      deleteTempUpload();
      // If uploaded/update image data, set reload state to true
      uploadedImageData && dispatch(editActions.reloadEditModal());
      // Clear editor data
      setEditorContent('');
      // Close editor
      setShowEditor(false);
    },
  };

  return (
    <FormModal show={show} closeModal={closeModal} options={modalOptions}>
      {/* Main Content */}
      <form
        className={`${classes['form__container']} ${commonClasses['custom_scrollbar']} ${commonClasses['custom_scrollbar__dark']}`}
        onSubmit={onFormSubmit}
        encType='multipart/form-data'
      >
        {/* Image Preview */}
        <div className={classes['img-preview__container']}>
          <p className={`label ${classes['preview__label']}`}>Preview</p>

          <div className={classes['img__wrapper']}>
            {imgData.url && <img src={imgData.url} alt='Preview' />}
            {updateOptions && (
              <img src={updateOptions.url} alt='To be updated' />
            )}
          </div>
        </div>

        {/* Description */}
        <div className={classes['desc__container']}>
          <label htmlFor='image-desc'>Description</label>
          <input
            id='image-desc'
            type='text'
            placeholder='One sentence description...'
            className={classes['desc__input']}
            value={imgDesc}
            onChange={(e) => {
              setImgDesc(e.target.value);
            }}
          ></input>
        </div>

        {/* Text Editor */}
        <TextEditorWrapper
          onChange={onEditorChange}
          data={editorContent}
          showEditor={showEditor}
          setShowEditor={setShowEditor}
        />

        {/* Action Buttons */}
        <div
          className={`${classes['btns__container']} ${
            updateOptions ? classes['btns__container_update'] : ''
          }`}
        >
          {!updateOptions && (
            <div className={classes['action-btns__container']}>
              <button
                className={`${classes['btn']} ${classes['btn-choose']}`}
                type='button'
              >
                Choose Photo
                <input
                  type='file'
                  accept='image/*'
                  id='upload-image'
                  onChange={imgSelectHandler}
                />
                <label
                  htmlFor='upload-image'
                  className={classes['upload-img__label']}
                ></label>
              </button>
            </div>
          )}

          <div className={classes['action-btns__container']}>
            <button
              className={`${classes['btn']} ${classes['btn-upload']} ${
                isUploadDisabled() ? classes['btn-upload__disabled'] : ''
              }`}
              type='submit'
            >
              {updateOptions ? 'Update' : 'Upload'}
            </button>
            <button
              className={`${classes['btn']} ${classes['btn-cancel']}`}
              type='button'
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </FormModal>
  );
};

export default memo(ImageUploadModal);

/* 
  TODO:
    - check after upload, reload edit img modal
*/
