import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UilPen } from '@iconscout/react-unicons';

import { contentActions } from '../../store/content';

import useAxios from '../../hooks/useAxios';
import useAxios2 from '../../hooks/useAxios2';
import axiosInstance from '../../util/apis';

import EditPhotoModal from '../auth/editPhotoModal/EditPhotoModal';

import { isAuthMode } from '../../util/util';

import classes from './About.module.css';
import mainContentClasses from '../content/MainContent.module.css';

const About = ({ tabName }) => {
  // Use user axios hook
  const {
    response: userResponse,
    error: userError,
    completed: userCompleted,
    sendRequest: fetchUser,
    reset: resetUserAxios,
  } = useAxios();

  // Use tabs axios hook
  const {
    response: tabsResponse,
    error: tabsError,
    loading: tabsLoading,
    completed: tabsCompleted,
    sendRequest: fetchTabs,
    reset: resetTabsAxios,
  } = useAxios();

  // Create state for currently selected tab
  const [activeTab, setActiveTab] = useState(0);

  // State fo reload about component
  const reloadAbout = useSelector((state) => state.edit.reloadAbout);

  // State for name and title
  const info = useSelector((state) => state.content.info);

  // State for about tabs
  const aboutTabs = useSelector((state) => state.content.tabs);

  // Create state for showing edit photo mode
  const [showEditPhotoModal, setShowEditPhotoModal] = useState(false);

  // Use location
  const location = useLocation();

  // Use dispatch
  const dispatch = useDispatch();

  // Check whether it is in auth mode
  const _isAuthMode = isAuthMode(location);

  // When components load
  // Set current tab
  // Fetch data
  useEffect(() => {
    // Fetch the name and title
    fetchUser({
      axiosInstance,
      url: '/users/me',
      method: 'GET',
    });

    // Fetch the about tabs
    fetchTabs({
      axiosInstance,
      url: '/about-tabs',
      method: 'GET',
    });
  }, [reloadAbout, dispatch, tabName, fetchUser, fetchTabs]);

  // Handle info GET request complete
  useEffect(() => {
    if (!userCompleted) return;

    if (!userError && userResponse) {
      dispatch(
        contentActions.setInfo({
          name: userResponse.name,
          title: userResponse.title,
          role: userResponse.role,
          photo: userResponse.url,
        })
      );

      setActiveTab(0);
    }

    resetUserAxios();
  }, [userCompleted, userResponse, userError, resetUserAxios, dispatch]);

  // Handle tabs GET request complete
  useEffect(() => {
    if (!tabsCompleted) return;

    if (!tabsError && tabsResponse) {
      dispatch(contentActions.setTabs(tabsResponse));
    }

    resetTabsAxios();
  }, [tabsCompleted, tabsResponse, tabsError, resetTabsAxios, dispatch]);

  return (
    <Fragment>
      {/* PHOTO MODAL */}
      {_isAuthMode && (
        <EditPhotoModal
          show={showEditPhotoModal}
          closeModal={setShowEditPhotoModal.bind(null, false)}
        />
      )}

      {/* ABOUT */}
      <main className={classes['main__container']}>
        <div className={classes['about__container']}>
          {/* Left Container */}
          <div
            className={`${classes['background__containers']} ${classes['left__container']}`}
          >
            {/* Image Cnntainer */}
            <div className={classes['img__container']}>
              <div className={classes['img__wrapper']}>
                <img src={info.photo} alt='Sharon Lau' />
                {_isAuthMode && (
                  <div
                    className={classes['photo_icon__container']}
                    onClick={setShowEditPhotoModal.bind(null, true)}
                  >
                    <UilPen className={classes['icon']} />
                  </div>
                )}
              </div>
            </div>

            {/* Name container */}
            <div className={classes['name__container']}>
              <p>{info.name}</p>
              <p>{info.role}</p>
            </div>

            {/* Tabs container */}
            <ul className={classes['tabs__container']}>
              {aboutTabs.map((tab, index) => (
                <li
                  className={`${classes['tab']} ${
                    activeTab === index ? classes['tab__active'] : ''
                  }`}
                  key={index}
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  {tab.name}
                </li>
              ))}
            </ul>
          </div>

          {/* Right Container */}
          {tabsLoading ? (
            <div
              className={`${classes['background__containers']} ${classes['right__container']} html__container`}
            >
              <div className={mainContentClasses['loading__container']}>
                <div className={mainContentClasses['spinner']}></div>
              </div>
            </div>
          ) : (
            <div
              className={`${classes['background__containers']} ${classes['right__container']} html__container`}
              dangerouslySetInnerHTML={{
                __html: aboutTabs[activeTab]?.content,
              }}
            ></div>
          )}
        </div>
      </main>
    </Fragment>
  );
};

export default About;
