import { useEffect, memo, useState, useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../ui/modal/Modal';
import ModalHeader from '../../ui/modalHeader/ModalHeader';
import ImageCards from './imageCard/ImageCards';
import ImageToolbar from './toolbar/ImageToolbar';

import ConfirmModal from '../../ui/confirmModal/ConfirmModal';
import useConfirmationModal from '../../../hooks/useConfirmationModal';

import ImageUploadModal from '../imageUploadModal/ImageUploadModal';
import EditTabsContentModal from '../editAbout/editTabsModal/EditTabsContentModal';

import useAxios from '../../../hooks/useAxios';
import axiosInstance from '../../../util/apis';

import useLoadingModal from '../../../hooks/useLoadingModal';

import useErrorModal from '../../../hooks/useErrorModal';

import { sameImageData } from '../../../util/processImage';
import { formatTabName } from '../../../util/util';
import { editActions } from '../../../store/edit';
import { routes } from '../../../util/config';

import modalClasses from '../../ui/modal/Modal.module.css';
import commonClasses from '../../../util/common.module.css';
import classes from './EditImageModal.module.css';

const ModalOverlay = memo(({ currentTab, closeModal }) => {
  // Use axios hook for fetch image metadata
  const {
    response: imageResponse,
    error: imageError,
    completed: imageCompleted,
    sendRequest: fetchImages,
    reset: resetImageAxios,
  } = useAxios();

  // Use axios hook for delete images
  const {
    response: deleteResponse,
    error: deleteError,
    completed: deleteCompleted,
    sendRequest: deleteImages,
    loading: deleteLoading,
    reset: resetDeleteAxios,
  } = useAxios();

  // Use axios hook for updating images orders
  const {
    error: arrangeError,
    completed: arrangeCompleted,
    sendRequest: arrangeImages,
    loading: arrangeLoading,
    reset: resetArrangeAxios,
  } = useAxios();

  // Create state for loading modal
  const { openLoadingModal, finish: finishLoading } = useLoadingModal();

  // Use error modal
  const openErrorModal = useErrorModal();

  // Create state for the images
  const [currentImgData, setCurrentImgData] = useState([]);

  // Create state for the images copy for arranging
  const [arrangeImgData, setArrangeImgData] = useState([]);

  // State of currently selected toolbar
  const activeToolbar = useSelector((state) => state.edit.activeToolbar);

  // State of the current toolbar action
  const toolbarAction = useSelector((state) => state.edit.toolbarAction);

  // State of currently selected images
  const selectedImgs = useSelector((state) => state.edit.selectedImgs);

  // State of image to be updated
  const imgToUpdate = useSelector((state) => state.edit.imgToUpdate);

  // State of reload edit image modal
  const reloadEditModal = useSelector((state) => state.edit.reloadEditModal);

  // Use dispatch
  const dispatch = useDispatch();

  // If editing about page
  const isAbout = currentTab === routes.ABOUT;

  // Use confirmation modal hook
  const {
    isShowModal: showConfirmModal,
    openModal: openConfirmModal,
    modalOptions: confirmModalOptions,
  } = useConfirmationModal({
    confirmBtnText: 'Delete',
    message: 'Are you sure you want to delete the selected item(s)?',
    onConfirm: () => {
      dispatch(editActions.setToolbarAction('delete'));
    },
    onCancel: () => {
      dispatch(editActions.enableToolbar());
      dispatch(editActions.clearToolbarStates());
    },
  });

  // Close upload modal, and reset toolbar
  const closeUploadModal = useCallback(() => {
    dispatch(editActions.enableToolbar());
    dispatch(editActions.clearToolbarStates());
  }, [dispatch]);

  // Set the current image data when first loads, and cleans up toolbar states when exit
  useEffect(() => {
    const url = isAbout
      ? '/about-tabs'
      : `/images/metadata/${formatTabName(currentTab, true)}?sign=true`;

    // Fetch initial data
    fetchImages({
      axiosInstance,
      url,
      method: 'GET',
    });

    return () => {
      // Clean up state when unmount
      dispatch(editActions.clearToolbarStates());
      dispatch(editActions.enableToolbar());
    };
  }, [dispatch, currentTab, fetchImages, reloadEditModal, isAbout]);

  // Handle completed GET image metadata request
  useEffect(() => {
    if (!imageCompleted) return;

    // If no error
    if (!imageError) {
      const fetchedImgData = [...imageResponse];

      // Set the state of image data on the current page
      setCurrentImgData(fetchedImgData);
      setArrangeImgData(fetchedImgData);

      // Reset axios state
      resetImageAxios();
    }
    // If error
    else {
      // Reset axios state
      resetImageAxios();

      // Generate error message
      const msg =
        imageError ||
        'An error occurred while loading the images. Please try again.';

      // Show the error modal
      openErrorModal(msg, null, 150);
    }
  }, [
    imageCompleted,
    imageResponse,
    imageError,
    resetImageAxios,
    openErrorModal,
  ]);

  // Handle completed DELETE image data request
  useEffect(() => {
    if (!deleteCompleted) return;

    // If no error
    if (!deleteError) {
      // Reset axios state
      resetDeleteAxios();

      finishLoading({
        onComplete: () => {
          // Enable toolbar
          dispatch(editActions.enableToolbar());

          // Reset toolbar states
          dispatch(editActions.clearToolbarStates());

          // Reload modal
          dispatch(editActions.reloadEditModal());
        },
        delay: 500,
      });
    }
    // If error
    else {
      // Generate error message
      const msg =
        deleteError ||
        'An error occurred while deleting the image. Please try again later.';

      // Reset axios state
      resetDeleteAxios();

      finishLoading({
        onComplete: () => {
          // Reset toolbar states
          dispatch(editActions.enableToolbar());

          // Reset toolbar states
          dispatch(editActions.clearToolbarStates());

          // Show error modal
          openErrorModal(msg, null, 150);
        },
        delay: 500,
      });
    }
  }, [
    deleteCompleted,
    deleteResponse,
    deleteError,
    resetDeleteAxios,
    dispatch,
    finishLoading,
    openErrorModal,
  ]);

  // Handle completed PATCH arrange image order request
  useEffect(() => {
    if (!arrangeCompleted) return;

    // Clear the toolbar action
    dispatch(editActions.clearToolbarAction());

    // If no error
    if (!arrangeError) {
      // Reset axios state
      resetArrangeAxios();

      finishLoading({
        onComplete: () => {
          // Enable toolbar
          dispatch(editActions.enableToolbar());

          // Reset toolbar states
          dispatch(editActions.clearToolbarStates());

          // Reload modal
          dispatch(editActions.reloadEditModal());
        },
        delay: 500,
        resetOnComplete: true,
      });
    }
    // If error
    else {
      // Generate error message
      const msg =
        arrangeError ||
        'An error occurred while arranging the image. Please try again later.';

      // Reset axios state
      resetArrangeAxios();

      finishLoading({
        onComplete: () => {
          // Enable toolbar
          dispatch(editActions.enableToolbar());

          // Reset toolbar states
          dispatch(editActions.clearToolbarStates());

          // Open error modal
          openErrorModal(msg, null, 150);
        },
        delay: 500,
        resetOnComplete: true,
      });
    }
  }, [
    arrangeCompleted,
    arrangeError,
    resetArrangeAxios,
    dispatch,
    finishLoading,
    openErrorModal,
  ]);

  // Handle toolbar actions
  useEffect(() => {
    // If no toolbar action, do nothing
    if (toolbarAction === '') return;

    // Disable toolbar until action is handled
    dispatch(editActions.disableToolbar());

    // Handle delete confirm action
    if (toolbarAction === 'delete-confirm') {
      openConfirmModal();
      return;
    }

    // Handle delete action
    if (toolbarAction === 'delete') {
      // If request is pending ore finished, do nothing
      if (deleteLoading || deleteCompleted) return;

      // If have valid selected images
      if (selectedImgs && selectedImgs.length > 0) {
        // Show loading modal
        openLoadingModal();

        // Construct url
        const url = isAbout
          ? `/about-tabs?ids=${selectedImgs.join(',')}`
          : `/images?tab=${formatTabName(
              currentTab,
              true
            )}&ids=${selectedImgs.join(',')}`;

        // Send request to delete the currently selected images
        deleteImages({
          axiosInstance,
          url,
          method: 'DELETE',
        });

        dispatch(editActions.clearToolbarStates());

        return;
      }
    }

    // Handle save arrangement action
    if (toolbarAction === 'save') {
      // If request is pending ore finished, do nothing
      if (arrangeLoading || arrangeCompleted) return;

      // Show loading modal
      openLoadingModal();

      // Construct the url
      const url = isAbout ? '/about-tabs/sort' : '/images/sort';

      // Send request to update the image order
      arrangeImages({
        axiosInstance,
        url,
        method: 'PATCH',
        data: {
          sort: arrangeImgData.map((img) => img.id),
          tab: formatTabName(currentTab, true),
        },
      });

      return;
    }

    // Handle Upload/Update action
    if (toolbarAction === 'upload' || toolbarAction === 'update') {
      // Modal open controlled by toolbarAction state

      // Clear active toolbar
      dispatch(editActions.clearActiveToolbar());

      return;
    }

    // Enable toolbar
    dispatch(editActions.enableToolbar());
    dispatch(editActions.clearToolbarStates());
  }, [
    toolbarAction,
    dispatch,
    selectedImgs,
    currentImgData,
    openConfirmModal,
    deleteImages,
    deleteLoading,
    deleteCompleted,
    currentTab,
    openLoadingModal,
    arrangeImages,
    arrangeLoading,
    arrangeCompleted,
    arrangeImgData,
    isAbout,
  ]);

  // If overlay container is clicked, close the modal
  const overlayContainerClickHandler = (e) => {
    // If target is svg, do nothing
    if (e.target.tagName === 'svg' || e.target.tagName === 'path') return;

    if (!e.target.className.includes) return;

    // Check if the target IS the overlay container and not its children
    e.target.className.includes(classes['overlay__container']) && closeModal();
  };

  const dragEndHandler = (result) => {
    const { source: src, destination: dest } = result;

    // If no valid destination, do nothing
    if (!dest) return;

    // If source and destination is the same, do nothing
    if (src.index === dest.index) return;

    // Copy the original data array into a new array
    const newImgData = [...arrangeImgData];

    // Remove the dragged element and save it
    const [draggedEl] = newImgData.splice(src.index, 1);

    // Add it back to the array at the destination index
    newImgData.splice(dest.index, 0, draggedEl);

    // Set the new state
    setArrangeImgData(newImgData);
  };

  // On arrange click, set sync arrangeImgData and currentImgData
  const onArrangeClick = () => {
    setArrangeImgData([...currentImgData]);
  };

  // If order currentImgData and arrangeImgData is the same, disable save button
  const disableArrangeSave = sameImageData(currentImgData, arrangeImgData);

  return (
    <div
      className={`${classes['overlay__container']} ${modalClasses['modal-overlay']} ${modalClasses['overlay-open']}`}
      onClick={overlayContainerClickHandler}
    >
      {/* Confirmation Modal */}
      <ConfirmModal show={showConfirmModal} options={confirmModalOptions} />

      {/* Image Update/Upload Modal */}
      <ImageUploadModal
        show={
          (toolbarAction === 'upload' || toolbarAction === 'update') && !isAbout
        }
        closeUploadModal={closeUploadModal}
        updateOptions={imgToUpdate}
        currentTab={currentTab}
      />

      {/* Edit Tabs Content Modal */}
      <EditTabsContentModal
        show={
          (toolbarAction === 'upload' || toolbarAction === 'update') && isAbout
        }
        closeModal={closeUploadModal}
        tabsData={imgToUpdate}
      />

      {/* Main Content */}
      <DragDropContext onDragEnd={dragEndHandler}>
        <div
          className={`${classes['edit__container']} ${commonClasses['custom_scrollbar']}`}
        >
          {/* Header */}
          <ModalHeader
            title={formatTabName(currentTab)}
            closeModal={closeModal}
          />

          {/* Toolbar */}
          <ImageToolbar
            onArrangeClick={onArrangeClick}
            disableArrangeSave={disableArrangeSave}
          />

          {/* Image list */}
          <ImageCards
            imgData={
              activeToolbar === 'arrange' ? arrangeImgData : currentImgData
            }
            activeToolbar={activeToolbar}
            isAbout={isAbout}
          />
        </div>
      </DragDropContext>
    </div>
  );
});

const EditImageModal = ({ show, closeModal, currentTab }) => {
  return (
    <Modal show={show} closeModal={closeModal} showCloseIcon={false}>
      {show && <ModalOverlay currentTab={currentTab} closeModal={closeModal} />}
    </Modal>
  );
};

export default memo(EditImageModal);

// imgData = {width, height, url, id, desc}
