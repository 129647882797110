import axios from 'axios';

import { ENV } from './config';

const BASE_URL_DEV = 'http://localhost:4000/api';
const BASE_URL_PROD = '/api';

const BASE_URL = ENV === 'dev' ? BASE_URL_DEV : BASE_URL_PROD;

export const RESOURCE_URL =
  ENV === 'dev' ? 'http://localhost:4000/img' : '/img';

export const deleteTempUpload = async () => {
  await axios.delete(`${BASE_URL}/images/temp`, {
    withCredentials: ENV === 'dev' ? true : undefined,
  });
};

export const uploadTempImage = async (data) => {
  const res = await axios.post(`${BASE_URL}/images/temp`, data, {
    withCredentials: ENV === 'dev' ? true : undefined,
  });

  return res.data.name;
};

export default axios.create({
  baseURL: BASE_URL,
  withCredentials: ENV === 'dev' ? true : undefined,
});
