import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UilInfoCircle } from '@iconscout/react-unicons';

import FormModal from '../../../ui/formModal/FormModal';

import useErrorModal from '../../../../hooks/useErrorModal';
import useLoadingModal from '../../../../hooks/useLoadingModal';

import useAxios from '../../../../hooks/useAxios';
import axiosInstance from '../../../../util/apis';

import { editActions } from '../../../../store/edit';

import classes from './EditNameModal.module.css';

const EditNameModal = ({ show, closeModal }) => {
  // Use user axios hook
  const {
    error,
    completed,
    loading,
    sendRequest,
    reset: resetAxios,
  } = useAxios();

  // Use dispatch
  const dispatch = useDispatch();

  // Create state for loading modal
  const { openLoadingModal, finish: finishLoading } = useLoadingModal();

  // Use error modal
  const openErrorModal = useErrorModal();

  // Global state for name and title
  const info = useSelector((state) => state.content.info);

  // Create state for input fields
  const [nameInput, setNameInput] = useState('');
  const [titleInput, setTitleInput] = useState('');
  const [roleInput, setRoleInput] = useState('');

  // Create state for form submit
  const [formSubmit, setFormSubmit] = useState(false);

  // Reset all states
  const resetAllStates = () => {
    setNameInput('');
    setTitleInput('');
    setRoleInput('');
  };

  const setInputs = useCallback(() => {
    setNameInput(info.name);
    setTitleInput(info.title);
    setRoleInput(info.role);
  }, [info]);

  // On first render set default input
  useEffect(() => {
    setInputs();
  }, [setInputs]);

  // If both name and title unchanged, disabled update button
  const disableUpdate =
    nameInput === info.name &&
    titleInput === info.title &&
    roleInput === info.role;

  // Handle PATCH request complete
  useEffect(() => {
    if (!completed) return;

    let onLoadingFinish = null;

    // If no error
    if (!error) {
      // On loading finish, close modal, and reload about
      onLoadingFinish = () => {
        resetAxios();
        setFormSubmit(false);
        closeModal();
        dispatch(editActions.reloadAbout());
      };
    }
    // If has error
    else {
      // Generate error message
      const msg =
        error || 'An error occurred during the update. Please try again.';

      // On loading finish, close modal, and reload about
      onLoadingFinish = () => {
        openErrorModal(msg, () => {
          resetAxios();
          setFormSubmit(false);
        });
      };
    }

    // Finish loading
    finishLoading({
      onComplete: onLoadingFinish,
      delay: 500,
    });
  }, [
    error,
    completed,
    resetAxios,
    dispatch,
    openErrorModal,
    finishLoading,
    closeModal,
  ]);

  // Handle form ready to submit
  useEffect(() => {
    // If not submitting form or active axios, do nothing
    if (!formSubmit || loading || completed) return;

    // Send the request
    // Fetch the name and title
    sendRequest({
      axiosInstance,
      url: '/users',
      method: 'PATCH',
      data: { name: nameInput, title: titleInput, role: roleInput },
    });

    // Open loading modal
    openLoadingModal('Updating');
  }, [
    formSubmit,
    loading,
    completed,
    sendRequest,
    nameInput,
    titleInput,
    roleInput,
    openLoadingModal,
  ]);

  // Handle submit on click
  const submitClickHandler = () => {
    if (disableUpdate) return;

    setFormSubmit(true);
  };

  // Form modal options
  const modalOptions = {
    title: 'Name',
    buttons: {
      confirm: {
        name: 'Update',
        isDisabled: disableUpdate,
        onClick: submitClickHandler,
      },
    },
    onExited: resetAllStates,
    onEntered: setInputs,
  };

  return (
    <FormModal show={show} closeModal={closeModal} options={modalOptions}>
      <form className={classes['form__container']}>
        {/* Name */}
        <div className={classes['input__container']}>
          <label htmlFor='name'>Name</label>
          <input
            id='name'
            type='text'
            value={nameInput}
            onChange={(e) => {
              setNameInput(e.target.value);
            }}
          />
        </div>

        {/* Title */}
        <div className={classes['input__container']}>
          <label htmlFor='title'>Title</label>
          <div className={classes['input__wrapper']}>
            <input
              id='title'
              type='text'
              value={titleInput}
              onChange={(e) => {
                setTitleInput(e.target.value);
              }}
            />
            <div className={classes['icon__wrapper']}>
              <UilInfoCircle className={classes['icon']} />
            </div>
            <span className={`${classes['tooltip']}`}>
              This is the text that appears below your name on the top of the
              page
            </span>
          </div>
        </div>

        {/* Role */}
        <div className={classes['input__container']}>
          <label htmlFor='role'>Role</label>
          <div className={classes['input__wrapper']}>
            <input
              id='role'
              type='text'
              value={roleInput}
              onChange={(e) => {
                setRoleInput(e.target.value);
              }}
            />
            <div className={classes['icon__wrapper']}>
              <UilInfoCircle className={classes['icon']} />
            </div>
            <span className={`${classes['tooltip']}`}>
              This is the text that appears below your name on the About page
            </span>
          </div>
        </div>
      </form>
    </FormModal>
  );
};

export default EditNameModal;
