import { useEffect, useRef } from 'react';

import TextEditor from '../../ui/textEditor/TextEditor';

import classes from './TextEditorWrapper.module.css';

const TextEditorWrapper = ({ onChange, data, showEditor, setShowEditor }) => {
  // Get container ref
  const wrapperRef = useRef(null);

  // calculate wrapper height and set appropriate height when state changes
  useEffect(() => {
    // If cannot find ref, do nothing
    if (!wrapperRef.current) {
      return;
    }

    // Set the hiehgt manually
    if (showEditor) {
      wrapperRef.current.style.height = 'auto';

      const { height } = wrapperRef.current.getBoundingClientRect();

      wrapperRef.current.style.height = 0;
      wrapperRef.current.style.transition = 'height 0.4s';
      let _ = wrapperRef.current.offsetHeight;
      wrapperRef.current.style.height = height + 'px';
    } else {
      wrapperRef.current.style.height = 0;
    }
  }, [showEditor]);

  // When tab is pressed, prevent it from going to the next element
  const preventTab = (e) => {
    if (e.which === 9) e.preventDefault();
  };

  // On show/close click, set state
  const onIconClick = () => {
    setShowEditor((prev) => !prev);
  };

  return (
    <div className={classes['editor__container']} onKeyDown={preventTab}>
      <div className={classes['label__container']}>
        <label>Additional Description</label>
        {/* <div
          className={`${classes['triangle']} ${
            showEditor ? classes['triangle-show'] : ''
          }`}
          onClick={onIconClick}
        ></div> */}
        <input type='checkbox' checked={showEditor} onChange={onIconClick} />
      </div>

      <div ref={wrapperRef} className={classes['editor__wrapper']}>
        <TextEditor allowImageUpload={true} onChange={onChange} data={data} />
      </div>
    </div>
  );
};

export default TextEditorWrapper;
