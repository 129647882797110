import { UilTimes } from '@iconscout/react-unicons';

import modalClasses from '../modal/Modal.module.css';
import classes from './ModalHeader.module.css';

const ModalHeader = ({ title, closeModal, color }) => {
  return (
    <div
      className={`${classes['edit__modal__wrapper']} ${classes['title__container']}`}
      style={{ backgroundColor: color || '#ae3ec9' }}
    >
      <p className={`${modalClasses['common-overlay__text']}`}>{title}</p>
      <div
        className={modalClasses['common-icon__wrapper']}
        onClick={closeModal}
      >
        <UilTimes className={modalClasses['common-icon']} />
      </div>
    </div>
  );
};

export default ModalHeader;
