import { ModalCSSTransition } from '../modal/Modal';
import { UilTimes } from '@iconscout/react-unicons';

import classes from './ConfirmModal.module.css';
import modalClasses from '../../ui/modal/Modal.module.css';

const ConfirmModal = ({ show, options }) => {
  const { title, confirmBtnText, cancelBtnText, message, onConfirm, onCancel } =
    options;

  const backdropClickHandler = (e) => {
    e.target.className.includes(classes['backdrop']) && options.onCancel();
  };

  return (
    <ModalCSSTransition show={show}>
      <div className={classes['backdrop']} onClick={backdropClickHandler}>
        <div className={classes['modal__container']}>
          {/* Title */}
          <div
            className={`${classes['edit-modal__wrapper']} ${classes['title__container']}`}
          >
            <p className={`${modalClasses['common-overlay__text__sml']}`}>
              {title || 'Confirm'}
            </p>
            <div
              className={modalClasses['common-icon__wrapper']}
              onClick={onCancel}
            >
              <UilTimes className={modalClasses['common-icon__sml']} />
            </div>
          </div>

          {/* Content */}
          <div className={classes['content__container']}>
            <p>{message}</p>
          </div>

          {/* Action buttons */}
          <div className={classes['btns__container']}>
            <button
              className={`${classes['btn']} ${classes['confirm__btn']}`}
              onClick={onConfirm}
            >
              {confirmBtnText || 'Confirm'}
            </button>

            <button
              className={`${classes['btn']} ${classes['cancel__btn']}`}
              onClick={onCancel}
            >
              {cancelBtnText || 'Cancel'}
            </button>
          </div>
        </div>
      </div>
    </ModalCSSTransition>
  );
};

export default ConfirmModal;
