import ReactDOM from 'react-dom';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';

import useAxios from '../../../hooks/useAxios2';
import axiosInstance from '../../../util/apis';
import { fullImgActions } from '../../../store/fullImg';
import { additionalDescContentActions } from '../../../store/additionalDescContent';

import modalClasses from '../../ui/modal/Modal.module.css';
import classes from './ImageModal.module.css';
import commonClasses from '../../../util/common.module.css';
import mainContentClasses from '../MainContent.module.css';

import {
  UilAngleLeftB,
  UilAngleRightB,
  UilTimes,
  UilInfoCircle,
  UilArrowUp,
} from '@iconscout/react-unicons';

const Backdrop = ({ closeImageModal }) => {
  return (
    <div className={classes['modal-backdrop']} onClick={closeImageModal}></div>
  );
};

const ModalOverlay = ({
  closeImageModal: closeModal,
  imgURL,
  hasFullImg,
  imgId,
  imgDesc,
  additionalDesc,
  moveTo,
  getMoveImage,
  showSpinner,
  setShowSpinner,
}) => {
  // Use media query hooks
  const matches = useMediaQuery('(max-width: 640px)');

  // Use ref
  const descContainerRef = useRef(null);
  const closeIconRef = useRef(null);
  const closeIcon640Ref = useRef(null);

  // State to show AD spinner when loading
  const [showADSpinner, setShowADSpinner] = useState(false);

  // Get additional description content state
  const additionalDescContents = useSelector(
    (state) => state.additionalDescContent.contents
  );

  // Use axios
  const { sendRequest, loading, completed } = useAxios();

  // Use dispatch
  const dispatch = useDispatch();

  // Fetch additional description content
  useEffect(() => {
    // If no additional description content, do nothing
    if (!additionalDesc) return;

    // Store the fetch full image URL function in a variable
    const fetchAdditionalDescContent = () => {
      setShowADSpinner(true);

      sendRequest(
        { axiosInstance, url: `/images/desc/${imgId}`, method: 'GET' },
        {
          resetOnComplete: true,
          onSuccess: (data) => {
            dispatch(
              additionalDescContentActions.add({
                id: imgId,
                additionalDescContent: data.content,
                expiresIn: data.expiresIn,
                timeReceived: Date.now(),
              })
            );
          },
          onError: () => {},
          onComplete: () => {
            setShowADSpinner(false);
          },
        }
      );
    };

    // Try to get the saved additional description content
    const _additionalDescContent = additionalDescContents[imgId];

    // If has saved additional description content, do nothing
    if (_additionalDescContent) {
      // If url additional description content, fetch for new additional description content
      if (
        _additionalDescContent.timeReceived + _additionalDescContent.expiresIn <
        Date.now()
      ) {
        fetchAdditionalDescContent();
      }

      return;
    }

    // Fetch full image URL
    fetchAdditionalDescContent();
  }, [additionalDesc, additionalDescContents, sendRequest, dispatch, imgId]);

  // If overlay container is clicked, close the modal
  const overlayContainerClickHandler = (e) => {
    if (!e.target.className.includes) return;

    const allowedClasses = [
      classes['img__wrapper'],
      classes['overlay__container'],
      classes['img__container'],
    ];

    const includesAllowedClasses = allowedClasses.some((cls) =>
      e.target.className.includes(cls)
    );

    includesAllowedClasses && closeModal();
  };

  const { prev, next } = getMoveImage();

  const spinnerJSX = (
    <div className={classes['desc_loading__container']}>
      <div
        className={`${classes['spinner__container']} ${mainContentClasses['loading__container']}`}
      >
        <div className={mainContentClasses['spinner']}></div>
      </div>
    </div>
  );

  const descContentJSX = (
    <Fragment>
      {/* Placeholder For Styling */}
      <div
        className={`${classes['icon__wrapper']} ${classes['icon__wrapper_up-hidden']}`}
      >
        <UilArrowUp className={classes['icon']} />
      </div>

      <div
        className={`html__container ${classes['adc_content__container']}`}
        dangerouslySetInnerHTML={{
          __html: additionalDescContents[imgId]?.additionalDescContent || '',
        }}
      ></div>

      {/* Scroll To Top Button */}
      <div
        className={`${classes['icon__wrapper']} ${classes['icon__wrapper_up']}`}
        onClick={() => {
          const iconRef = matches ? closeIcon640Ref : closeIconRef;

          iconRef.current?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        <UilArrowUp className={classes['icon']} />
      </div>
    </Fragment>
  );

  const descContainerJSX = (
    <div className={classes['desc__container']} ref={descContainerRef}>
      {showADSpinner ? spinnerJSX : descContentJSX}
    </div>
  );

  return (
    <div
      className={`${classes['overlay__container']} ${modalClasses['modal-overlay']} ${modalClasses['overlay-open']} ${commonClasses['custom_scrollbar']}`}
      onClick={overlayContainerClickHandler}
    >
      {/* Main Image Container */}
      <div className={classes['main-image__container']}>
        {/* Loading */}
        {showSpinner && (
          <div className={classes['loading__container']}>
            <div
              className={`${classes['spinner__container']} ${mainContentClasses['loading__container']}`}
            >
              <div className={mainContentClasses['spinner']}></div>
            </div>
          </div>
        )}

        {/* When viewport < 640px */}
        {matches && (
          <Fragment>
            {/* Icon Left */}
            <div className={classes['icons__container']}>
              <div
                className={classes['icon__wrapper']}
                onClick={moveTo.bind(null, prev)}
              >
                <UilAngleLeftB className={classes['icon']} />
              </div>

              {/* Icon Info */}
              <div
                className={`${classes['icon__wrapper']} ${
                  classes['icon__wrapper_info']
                } ${
                  !additionalDesc ? classes['icon__wrapper_info-disabled'] : ''
                }`}
                onClick={() => {
                  descContainerRef.current?.scrollIntoView({
                    behavior: 'smooth',
                  });
                }}
              >
                <UilInfoCircle className={classes['icon']} />
              </div>

              {/* Icon Close */}
              <div
                ref={closeIcon640Ref}
                className={`${classes['icon__wrapper']} ${classes['icon__wrapper_close']}`}
                onClick={closeModal}
              >
                <UilTimes className={classes['icon']} />
              </div>

              {/* Icon Right */}
              <div
                className={classes['icon__wrapper']}
                onClick={moveTo.bind(null, next)}
              >
                <UilAngleRightB className={classes['icon']} />
              </div>
            </div>
          </Fragment>
        )}

        {/* Left Icon */}
        {!matches && (
          <div
            className={classes['icon__wrapper']}
            onClick={moveTo.bind(null, prev)}
          >
            <UilAngleLeftB className={classes['icon']} />
          </div>
        )}

        {/* Image Container */}
        <div className={`${classes['img__container']}`}>
          <div className={classes['img__wrapper']}>
            <img
              src={imgURL}
              alt={imgDesc}
              className={commonClasses['no-select']}
              onLoad={() => {
                if (hasFullImg) {
                  setShowSpinner(false);
                }
              }}
            />
          </div>

          {/* Image Description */}
          <div className={`${classes['img-description']}`}>
            <p>{imgDesc}</p>
          </div>
        </div>

        {/* When viewport >= 640px */}
        {!matches && (
          <Fragment>
            {/* Icon Right */}
            <div
              className={classes['icon__wrapper']}
              onClick={moveTo.bind(null, next)}
            >
              <UilAngleRightB className={classes['icon']} />
            </div>

            {/* Icon Close */}
            <div
              ref={closeIconRef}
              className={`${classes['icon__wrapper']} ${classes['icon__wrapper_close']}`}
              onClick={closeModal}
            >
              <UilTimes className={classes['icon']} />
            </div>

            {/* Icon Info */}
            <div
              className={`${classes['icon__wrapper']} ${
                classes['icon__wrapper_info']
              } ${
                !additionalDesc ? classes['icon__wrapper_info-disabled'] : ''
              }`}
              onClick={() => {
                descContainerRef.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              <UilInfoCircle className={classes['icon']} />
            </div>
          </Fragment>
        )}
      </div>

      {/* Description Container */}
      {additionalDesc && !showSpinner && descContainerJSX}
    </div>
  );
};

const ImageModal = ({ moveTo, dataName, imgData, closeImageModal }) => {
  const fullImgs = useSelector((state) => state.fullImg.fulImgURL);
  const fullImgURL = fullImgs[dataName];

  // State to show spinner when loading
  const [showSpinner, setShowSpinner] = useState(false);

  // Find index in image data
  const imgIndex = imgData.findIndex((img) => img.dataName === dataName);

  // Use dispatch
  const dispatch = useDispatch();

  // Use axios hook
  const {
    sendRequest: fetchFullImgURL,
    loading: fullImgURLLoading,
    completed: fullImgURLCompleted,
  } = useAxios();

  // Fetch for full resolution image when modal is opened or params change
  useEffect(() => {
    // If no valid image data, do nothing
    if (!imgData || !Array.isArray(imgData) || imgData.length <= 0) return;

    // If cannot find image index, do nothing
    if (imgIndex < 0) return;

    // If active request , do nothing
    if (fullImgURLLoading) return;

    // Store the fetch full image URL function in a variable
    const fetchURL = () => {
      setShowSpinner(true);

      fetchFullImgURL(
        { axiosInstance, url: `/images/${dataName}`, method: 'GET' },
        {
          resetOnComplete: true,
          onSuccess: (data) => {
            dispatch(
              fullImgActions.add({
                name: data.id,
                url: data.url,
                expiresIn: data.expiresIn,
                timeReceived: Date.now(),
              })
            );
          },
          onError: () => {},
          onComplete: () => {},
        }
      );
    };

    const _fullImgURL = fullImgs[dataName];

    // If has full image URL, do nothing
    if (_fullImgURL) {
      // If url expired, fetch for new URL
      if (_fullImgURL.timeReceived + _fullImgURL.expiresIn < Date.now()) {
        fetchURL();
      }

      return;
    }

    // Fetch full image URL
    fetchURL();
  }, [
    imgData,
    imgIndex,
    fullImgURLLoading,
    fullImgURLCompleted,
    fullImgs,
    fetchFullImgURL,
    dataName,
    dispatch,
  ]);

  // If no image found, return nothing
  if (imgIndex < 0) return null;

  // Get description and url of the image
  const { url, desc, additionalDesc, id } = imgData[imgIndex];

  // Get the prev image name and next image name
  const getMoveImage = () => {
    // Calculate the next index, if overflow, wrap around
    const nextIndex = imgIndex + 1 >= imgData.length ? 0 : imgIndex + 1;

    // Calculate the next index, if overflow, wrap around
    const prevIndex = imgIndex - 1 < 0 ? imgData.length - 1 : imgIndex - 1;

    return {
      next: imgData[nextIndex].dataName,
      prev: imgData[prevIndex].dataName,
    };
  };

  // return null;

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop closeImageModal={closeImageModal} />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          imgURL={fullImgURL ? fullImgURL.url : url}
          hasFullImg={fullImgURL}
          imgId={id}
          imgDesc={desc}
          additionalDesc={additionalDesc}
          moveTo={moveTo}
          getMoveImage={getMoveImage}
          closeImageModal={closeImageModal}
          showSpinner={showSpinner}
          setShowSpinner={setShowSpinner}
        />,
        document.getElementById('overlay-root')
      )}
    </Fragment>
  );
};

export default ImageModal;
