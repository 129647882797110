import { useEffect, useRef } from 'react';

import classes from './ImageBlock.module.css';

const ImageBlock = ({ imgData, showImageModal }) => {
  const { width, height, left, top, url } = imgData;

  // Reference to the container
  const containerRef = useRef();

  // Initiazlie container style and fetch for full image
  useEffect(() => {
    const containerEl = containerRef.current;

    // Set the container width and position
    containerEl.style.width = `${width.toFixed(3)}px`;
    containerEl.style.height = `${height.toFixed(3)}px`;
    containerEl.style.left = `${left.toFixed(3)}px`;
    containerEl.style.top = `${top.toFixed(3)}px`;
    containerEl.style.display = 'block';
  }, [width, height, left, top]);

  return (
    <div
      ref={containerRef}
      className={classes['img__container']}
      onClick={showImageModal}
    >
      <img src={url} alt='something something' />
    </div>
  );
};

export default ImageBlock;
