import { useDispatch, useSelector } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';

import { loadingActions } from '../store/loading';

const defaultFinishOptions = {
  onComplete: null,
  delay: 0,
};

const useLoadingModal = () => {
  const [onCompleteTimeout, setOnCompleteTimeout] = useState(null);
  const [finishOptions, setFinishOptions] = useState(defaultFinishOptions);

  const loadingState = useSelector((state) => state.loading.state);

  const dispatch = useDispatch();

  const finish = useCallback(
    (options) => {
      setFinishOptions({ ...defaultFinishOptions, ...options });
      dispatch(loadingActions.finish());
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    if (onCompleteTimeout) {
      clearTimeout(onCompleteTimeout);
    }

    setOnCompleteTimeout(null);

    dispatch(loadingActions.reset());
    dispatch(loadingActions.hide());
  }, [onCompleteTimeout, dispatch]);

  const openLoadingModal = useCallback(
    (title) => {
      dispatch(loadingActions.reset());
      dispatch(loadingActions.setTitle(title));
      dispatch(loadingActions.show());
    },
    [dispatch]
  );

  useEffect(() => {
    if (loadingState === 'complete' && !onCompleteTimeout) {
      const { onComplete, delay } = finishOptions;

      const timeout = setTimeout(() => {
        onComplete && onComplete();
        reset();
        dispatch(loadingActions.hide());
      }, delay);

      setOnCompleteTimeout(timeout);
    }
  }, [loadingState, onCompleteTimeout, finishOptions, reset, dispatch]);

  return {
    openLoadingModal,
    finish,
  };
};

export default useLoadingModal;
