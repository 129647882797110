import { useState, useEffect, memo } from 'react';
import { useElementSize, useDebounce, useLockedBody } from 'usehooks-ts';
import { useNavigate, useLocation } from 'react-router-dom';

import ImageBlock from './ImageBlock';
import ImageModal from '../modal/ImageModal';

import { processLayout } from '../../../util/processImage';
import { deounceDelay } from '../../../util/config';
import { getLastParams } from '../../../util/util';

import classes from './Gallery.module.css';

const Gallery = ({ imgData, layoutOptions }) => {
  // Create state for image layout data
  const [imgLayout, setImgLayout] = useState(null);

  // Create state for image modal
  const [imgModalState, setImgModalState] = useState({
    show: false,
    imgURL: null,
    imgDesc: '',
    imgDataName: null,
    currentIndex: 0,
  });

  // Get width of container element
  const [containerRef, { width: containerWidth }] = useElementSize();

  // Use debounce for container width
  const debouncedWidth = useDebounce(containerWidth, deounceDelay);

  const location = useLocation();
  const navigate = useNavigate();

  // Set lock scrollbar to same value as show
  useLockedBody(location.search, 'root');

  // Calculate the image layout data and set the container height
  useEffect(() => {
    if (debouncedWidth <= 0) return;

    const { processedLayoutData, containerTargetHeight } = processLayout(
      imgData,
      { ...layoutOptions, rect: { width: debouncedWidth } }
    );

    const containerEl = document.querySelector(
      `.${classes['gallery__container']}`
    );
    containerEl.style.height = `${containerTargetHeight.toFixed(3)}px`;

    setImgLayout(processedLayoutData);
  }, [imgData, layoutOptions, debouncedWidth]);

  // Open modal
  const openImageModal = (index) => {
    // Set the current image url, description, index in state
    // setImgModalState({
    //   show: true,
    //   imgURL: imgData[index].url,
    //   imgDesc: imgData[index].desc,
    //   imgDataName: imgData[index].dataName,
    //   currentIndex: index,
    // });

    navigate(`${location.pathname}?id=${imgData[index].dataName}`);
  };

  // Close modal
  const closeImageModal = () => {
    // Set the modal state to hide modal
    // setImgModalState((prev) => ({ ...prev, show: false }));

    navigate(location.pathname);
  };

  // Move to the next image in modal
  const moveToNextImage = () => {
    // Calculate the next index, if at the end, wrap around
    const newIndex =
      imgModalState.currentIndex + 1 >= imgData.length
        ? 0
        : imgModalState.currentIndex + 1;

    // Update the modal state
    setImgModalState((prev) => ({
      ...prev,
      imgURL: imgData[newIndex].url,
      imgDesc: imgData[newIndex].desc,
      imgDataName: imgData[newIndex].dataName,
      currentIndex: newIndex,
    }));
  };

  // Move to the previous image in modal
  const moveToPrevImage = () => {
    // Calculate the next index, if at the end, wrap around
    const newIndex =
      imgModalState.currentIndex - 1 < 0
        ? imgData.length - 1
        : imgModalState.currentIndex - 1;

    // Update the modal state
    setImgModalState((prev) => ({
      ...prev,
      imgURL: imgData[newIndex].url,
      imgDesc: imgData[newIndex].desc,
      imgDataName: imgData[newIndex].dataId,
      currentIndex: newIndex,
    }));
  };

  return (
    <div ref={containerRef} className={classes['gallery__container']}>
      {/* <ImageModal
        show={imgModalState.show}
        imgURL={imgModalState.imgURL}
        imgDesc={imgModalState.imgDesc}
        imgDataName={imgModalState.imgDataName}
        moveImage={{ moveToNextImage, moveToPrevImage }}
        closeImageModal={closeImageModal}
      /> */}

      {location.search && (
        <ImageModal
          dataName={getLastParams(location.search, '=')}
          imgData={imgData}
          moveTo={(dataName) => {
            navigate(`${location.pathname}?id=${dataName}`);
          }}
          closeImageModal={closeImageModal}
        />
      )}

      {imgLayout &&
        imgLayout.map((layout, i) => {
          return (
            <ImageBlock
              imgData={layout}
              key={i}
              showImageModal={openImageModal.bind(null, i)}
            />
          );
        })}
    </div>
  );
};

export default memo(Gallery);
