import { createSlice } from '@reduxjs/toolkit';

/* 
  STATE:
    PENDING
    OPEN
    CLOSING
    CLOSED
 */

const intialErrorState = {
  state: 'pending',
  message: 'An error occurred. Please try again. 🤷‍♂️',
};

const errorSlice = createSlice({
  name: 'error',
  initialState: intialErrorState,
  reducers: {
    open(state, action) {
      state.message = action.payload;
      state.state = 'open';
    },
    close(state) {
      state.state = 'closing';
    },
    closed(state) {
      state.state = 'closed';
    },
    clear(state) {
      state.message = intialErrorState.message;
      state.state = intialErrorState.state;
    },
  },
});

export const errorActions = errorSlice.actions;

export default errorSlice.reducer;
