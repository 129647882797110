import { useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Gallery from './gallery/Gallery';
import { contentActions } from '../../store/content';
import { formatTabName } from '../../util/util';

import useAxios from '../../hooks/useAxios';
import axiosInstance from '../../util/apis';

import classes from './MainContent.module.css';

const MainContent = ({ tabName }) => {
  // Get state for reloading main content component
  const reloadMainContent = useSelector(
    (state) => state.edit.reloadMainContent
  );

  // Get state for image data
  const imgData = useSelector((state) => state.content.imgData);

  // Get state for tab description
  const tabDesc = useSelector((state) => state.content.tabDescription);

  // Get state for layout options
  const layoutOptions = useSelector((state) => state.content.layoutOptions);

  // Use images axios hook
  const {
    response: imageResponse,
    error: imageError,
    completed: imageCompleted,
    loading: imageLoading,
    sendRequest: fetchImages,
    reset: resetImageAxios,
  } = useAxios();

  // Use tab axios hook
  const {
    response: tabResponse,
    error: tabError,
    completed: tabCompleted,
    loading: tabLoading,
    sendRequest: fetchTabDescription,
    reset: resetTabAxios,
  } = useAxios();

  // Use dispatch
  const dispatch = useDispatch();

  // Fetch image data for current tab
  useEffect(() => {
    // Fetch images
    fetchImages({
      axiosInstance,
      url: `/images/metadata/${formatTabName(tabName, true)}`,
      method: 'GET',
    });

    // Fetch current tab description
    fetchTabDescription({
      axiosInstance,
      url: `/tabs/${formatTabName(tabName, true)}`,
      method: 'GET',
    });

    // Reset the full image data
    // dispatch(fullImgActions.reset());
  }, [tabName, dispatch, fetchImages, fetchTabDescription, reloadMainContent]);

  // Handle images fetch complete
  useEffect(() => {
    if (!imageCompleted) return;

    // If no error
    if (!imageError) {
      const fetchedImgData = [...imageResponse];

      // Set the image data
      dispatch(contentActions.setImgData(fetchedImgData));
    }
    // If error
    else {
      // TODO: handle error
    }

    // Reset axios state
    resetImageAxios();
  }, [
    imageCompleted,
    imageResponse,
    imageError,
    resetImageAxios,
    dispatch,
    tabName,
  ]);

  // Handle tab description fetch complete
  useEffect(() => {
    if (!tabCompleted) return;

    // If no error
    if (!tabError) {
      const desc = tabResponse.desc;

      dispatch(contentActions.setTabDescription(desc));
    }

    // Reset axios state
    resetTabAxios();
  }, [tabResponse, tabCompleted, tabError, dispatch, resetTabAxios]);

  return (
    <main className={classes['main__container']}>
      {!tabLoading && (
        <div className={classes['content_description__container']}>
          <p className={classes['content-description']}>{tabDesc}</p>
        </div>
      )}

      {imageLoading ? (
        <div className={classes['loading__container']}>
          <div className={classes['spinner']}></div>
        </div>
      ) : (
        <Gallery imgData={imgData} layoutOptions={layoutOptions} />
      )}
    </main>
  );
};

export default memo(MainContent);
