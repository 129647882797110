import { useSelector } from 'react-redux';

import Spinner from './Spinner';
import { ModalCSSTransition } from '../modal/Modal';

import classes from './LoadingModal.module.css';
import modalClasses from '../../ui/modal/Modal.module.css';

const LoadingModal = () => {
  const title = useSelector((state) => state.loading.title);
  const show = useSelector((state) => state.loading.show);

  return (
    <ModalCSSTransition
      show={show}
      classNames={{
        enter: '',
        enterActive: classes['slide-in'],
        exit: '',
        exitActive: classes['slide-out'],
        appear: '',
        appearActive: '',
      }}
      timeout={300}
    >
      <div className={classes['main__container']}>
        <p
          className={`${modalClasses['common-overlay__text__sml']} ${classes['header__text']}`}
        >
          {title}
        </p>

        <Spinner />
      </div>
    </ModalCSSTransition>
  );
};

export default LoadingModal;
