import { useState, useCallback } from 'react';

const useAxios = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const reset = useCallback(() => {
    setResponse(null);
    setError('');
    setLoading(false);
    setCompleted(false);
  }, []);

  const sendRequest = useCallback(
    async (config, options) => {
      const { axiosInstance, method, url, data = {} } = config;
      const {
        onComplete = () => {},
        onSuccess = (data) => {},
        onError = (err) => {},
        resetOnComplete = false,
      } = options;

      try {
        setLoading(true);

        const res = await axiosInstance[method.toLowerCase()](url, data);

        setResponse(res.data);

        onSuccess(res.data);
      } catch (err) {
        setError(err.response.data.message);

        onError(err.response.data.message);
      } finally {
        setLoading(false);
        setCompleted(true);

        onComplete();
        resetOnComplete && reset();
      }
    },
    [reset]
  );

  return { response, error, loading, completed, sendRequest, reset };
};

export default useAxios;

/* 
  USAGE EXAMPLE

  // Use Hook
  const { sendRequest: fetchOrgs } = useAxios();

  // Send Request
  fetchOrgs(
        { axiosInstance, url: '/orgs', method: 'GET' },
        {
          resetOnComplete: true,
          onSuccess: (data) => {
            ...
          },
          onError: (err) => {
            ...
          },
          onComplete: () => {
            ...
          },
        }
      );
*/
