import { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { loadingActions } from '../../../store/loading';

import classes from './Spinner.module.css';

const getSpinnerAngle = (spinnerEl) => {
  // Get the computed styles of the element
  const computedStyles = window.getComputedStyle(spinnerEl);

  // Get the transform property
  const transform = computedStyles.getPropertyValue('transform');

  // Extract the rotation values from the transform property
  const matrix = transform.match(/^matrix\((.+)\)$/)[1].split(',');
  return (
    (Math.atan2(parseFloat(matrix[1]), parseFloat(matrix[0])) * 180) / Math.PI
  );
};

const Spinner = () => {
  // Create states
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [spinnerClass, setSpinnerClass] = useState(
    `${classes['spinner']} ${classes['spinner_spin']}`
  );

  // Use dispatch
  const dispatch = useDispatch();

  // Get global state
  const loadingState = useSelector((state) => state.loading.state);

  // Get the spinner element reference
  const spinnerRef = useRef();

  // Finish the spinner animation
  const finish = useCallback(() => {
    const angle = spinnerRef.current ? getSpinnerAngle(spinnerRef.current) : 0;

    spinnerRef.current.style.setProperty(
      '--spinner-rotation',
      `${Math.round(angle)}deg`
    );

    setSpinnerClass(`${classes['spinner']} ${classes['spinner_complete']}`);
  }, []);

  // Execute finish function when finish state is set
  useEffect(() => {
    // If state is finish, play finishing animation
    if (loadingState === 'finish') {
      finish();
    }

    // Reset the spinner states
    if (loadingActions === 'reset') {
      setShowCheckmark(false);
      setSpinnerClass(`${classes['spinner']} ${classes['spinner_spin']}`);
    }
  }, [loadingState, finish]);

  // Spinner animation end handler
  // Plays spinner fill animation after finish is called
  // Display checkmark after spinner fill animation finishes
  const spinnerAnimEndHandler = (e) => {
    if (
      e.target.classList.contains(classes['spinner_fill']) &&
      e.target.classList.contains(classes['spinner_complete'])
    ) {
      setShowCheckmark(true);
    }

    if (e.target.classList.contains(classes['spinner_complete'])) {
      setSpinnerClass((prev) => prev.concat(` ${classes['spinner_fill']}`));
    }
  };

  // Checkmark animation end handler
  // Executes callback when checkmark animation finishes
  const checkmarkAnimEndHandler = () => {
    dispatch(loadingActions.complete());
  };

  return (
    <div
      ref={spinnerRef}
      className={spinnerClass}
      onClick={finish}
      onAnimationEnd={spinnerAnimEndHandler}
    >
      {showCheckmark && (
        <span
          className={classes['spinner_checkmark']}
          onAnimationEnd={checkmarkAnimEndHandler}
        ></span>
      )}
    </div>
  );
};

export default Spinner;
