// Define a function to get the index of the minimum heights column array
const getMinColumnHeightsIndex = (heights) => {
  let minIndex = 0;

  for (let i = 1; i < heights.length; i++) {
    if (heights[i] < heights[minIndex]) {
      minIndex = i;
    }
  }

  return minIndex;
};

// Define a function to get the index of the maximum heights column array
const getMaxColumnHeightsIndex = (heights) => {
  let maxIndex = 0;

  for (let i = 1; i < heights.length; i++) {
    if (heights[i] > heights[maxIndex]) {
      maxIndex = i;
    }
  }

  return maxIndex;
};

export const processLayout = (imgData, options) => {
  const { rect, columns, gap } = options;

  // Calculate the target width of each image
  const imgTargetWidth = (rect.width - gap * (columns - 1)) / columns;

  // Array to hold current height of each column
  const columnHeights = [];

  // The starting left position for the image corresponding to the column index
  const imgLeftPos = [];

  // Initialize column heights and calculate image left positions
  for (let i = 0; i < columns; i++) {
    columnHeights.push(0);
    imgLeftPos.push(i * (imgTargetWidth + gap));
  }

  const processedLayoutData = imgData.map((img) => {
    // Calculate the image height based on the targetWidth
    const imgTargetHeight = (imgTargetWidth * img.height) / img.width;

    // Get the minimum column heights index
    const colIndex = getMinColumnHeightsIndex(columnHeights);

    // Save the layout data for this image
    const data = {
      width: imgTargetWidth,
      height: imgTargetHeight,
      left: imgLeftPos[colIndex],
      top: columnHeights[colIndex],
      url: img.url,
      desc: img.desc,
      dataName: img.dataName,
    };

    // console.log(data);

    // Update column heights
    columnHeights[colIndex] += imgTargetHeight + gap;

    // Return layout data for current image
    return data;
  });

  // Return layout data and the maximum height
  return {
    processedLayoutData,
    containerTargetHeight:
      columnHeights[getMaxColumnHeightsIndex(columnHeights)],
  };
};

export const sameImageData = (data1, data2) => {
  if (data1.length !== data2.length) return false;

  for (let i = 0; i < data1.length; i++) {
    if (data1[i].id !== data2[i].id) return false;
  }

  return true;
};
