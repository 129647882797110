import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import classes from './Navbar.module.css';

import { routes } from '../../util/config';
import { isAuthMode } from '../../util/util';

const Navbar = () => {
  const location = useLocation();

  const navClass = (navData) =>
    navData.isActive
      ? `${classes['nav_link']} ${classes['nav_link__active']}`
      : classes['nav_link'];

  // If in auth mode, append /auth to the URL
  const generateURL = (name) => {
    // Return the proper link name
    return `${isAuthMode(location) ? '/auth' : ''}${name}`;
  };

  return (
    <nav>
      <ul className={classes['nav-container']}>
        <li>
          <NavLink to={generateURL(routes.ENVIRONMENT)} className={navClass}>
            Environment
          </NavLink>
        </li>
        <li>
          <NavLink
            to={generateURL(routes.CHARACTER_AND_COSTUME)}
            className={navClass}
          >
            Character and Costume
          </NavLink>
        </li>
        <li>
          <NavLink to={generateURL(routes.PROP_DESIGN)} className={navClass}>
            Prop Design
          </NavLink>
        </li>
        <li>
          <NavLink to={generateURL(routes.THREE_D)} className={navClass}>
            3D
          </NavLink>
        </li>
        <li>
          <NavLink
            to={generateURL(routes.PLEIN_AIR_AND_OTHERS)}
            className={navClass}
          >
            Plein Air and Others
          </NavLink>
        </li>
        <li>
          <NavLink to={generateURL(routes.ABOUT)} className={navClass}>
            About
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
