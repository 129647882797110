import ReactDOM from 'react-dom';
import { Fragment } from 'react';
import { useLockedBody } from 'usehooks-ts';
import { CSSTransition } from 'react-transition-group';
import { UilTimes } from '@iconscout/react-unicons';

import { modalAnimationTiming } from '../../../util/config';

import classes from './Modal.module.css';

export const IconWrapper = (props) => {
  const classNames = `${classes['icon-wrapper']} ${classes['modal-overlay']} ${props.className}`;

  return (
    <div className={classNames} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export const ModalCSSTransition = (props) => {
  return (
    <CSSTransition
      in={props.show}
      mountOnEnter
      unmountOnExit
      timeout={props.timeout || modalAnimationTiming}
      classNames={props.classNames || defaultTransitionClasses}
      onEnter={props.onEnter}
      onEntered={props.onEntered}
      onExit={props.onExit}
      onExited={props.onExited}
    >
      <Fragment>{props.children}</Fragment>
    </CSSTransition>
  );
};

const Backdrop = ({
  show,
  closeImageModal,
  showCloseIcon,
  zIndex,
  onExited,
  onEntered,
}) => {
  const defaultZIndex = 5;

  return (
    <CSSTransition
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={modalAnimationTiming}
      classNames={{
        enter: '',
        enterActive: classes['backdrop-open'],
        exit: '',
        exitActive: classes['backdrop-close'],
        appear: '',
        appearActive: '',
      }}
      onExited={onExited}
      onEntered={onEntered}
    >
      <div
        className={classes['modal-backdrop']}
        onClick={closeImageModal}
        style={{ zIndex: zIndex ? zIndex.toString() : defaultZIndex }}
      >
        {/* Close modal button */}
        <ModalCSSTransition
          show={show && showCloseIcon}
          classNames={defaultTransitionClasses}
        >
          <IconWrapper
            className={classes['icon-wrapper_close']}
            onClick={closeImageModal}
          >
            <UilTimes className={classes['icon']} />
          </IconWrapper>
        </ModalCSSTransition>
      </div>
    </CSSTransition>
  );
};

const Modal = (props) => {
  const { show, closeModal, showCloseIcon } = props;

  // Set lock scrollbar to same value as show
  useLockedBody(show, 'root');

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop
          show={show}
          closeImageModal={closeModal}
          showCloseIcon={showCloseIcon === undefined ? true : showCloseIcon}
          zIndex={props.zIndex}
          onExited={props.onExited}
          onEntered={props.onEntered}
        />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        props.children,
        document.getElementById('overlay-root')
      )}
    </Fragment>
  );
};

export const defaultTransitionClasses = {
  enter: '',
  enterActive: classes['overlay-open'],
  exit: '',
  exitActive: classes['overlay-close'],
  appear: '',
  appearActive: '',
};

export default Modal;
