import { createSlice } from '@reduxjs/toolkit';

const initialFullImgState = {
  fullImgData: [],
  fulImgURL: {},
};

const fullImgSlice = createSlice({
  name: 'fullImg',
  initialState: initialFullImgState,
  reducers: {
    add(state, action) {
      const { name, url, expiresIn, timeReceived } = action.payload;

      const newFullImgURL = { ...state.fulImgURL };
      newFullImgURL[name] = { url, expiresIn, timeReceived };

      state.fulImgURL = newFullImgURL;
    },
    remove(state, action) {
      const { name } = action.payload;

      const newFullImgURL = { ...state.fulImgURL };

      delete newFullImgURL[name];

      state.fulImgURL = newFullImgURL;
    },
    reset(state) {
      state.fulImgURL = {};
    },
  },
});

export const fullImgActions = fullImgSlice.actions;

export default fullImgSlice.reducer;
