import { createSlice } from '@reduxjs/toolkit';

const initialAdditionalDescContentState = {
  contents: {},
};

const additionalDescContentSlice = createSlice({
  name: 'additionalDescContent',
  initialState: initialAdditionalDescContentState,
  reducers: {
    add(state, action) {
      const { id, additionalDescContent, expiresIn, timeReceived } =
        action.payload;

      const newContents = { ...state.contents };
      newContents[id] = { additionalDescContent, expiresIn, timeReceived };

      state.contents = newContents;
    },
    remove(state, action) {
      const { id } = action.payload;

      const newContents = { ...state.contents };

      delete newContents[id];

      state.contents = newContents;
    },
    reset(state) {
      state.contents = {};
    },
  },
});

export const additionalDescContentActions = additionalDescContentSlice.actions;

export default additionalDescContentSlice.reducer;
